import { service, BASE_URL } from "../request";
const sourceExchangeAPI = {
    /* 获取源力兑换列表 */
    getSourceList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/integral/integralOrder/list`,
            params
        });
    },
    /* 获取源力值根据ID */
    getSourceById(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/integral/integralOrder/${params.id}`,
            params
        });
    },
    // 根据ID删除某一个
    deleteSourceById(data:{id:string}) {
        return service({
            url: `${BASE_URL}/api/admin/integral/integralOrder/${data.id}`,
            data,
            method:'DELETE'
        });
    },
    // 根据ID修改状态
    updateSourceById(data:{id:any,state:any}) {
        return service({
            url: `${BASE_URL}/api/admin/integral/integralOrder/${data.id}`,
            data,
            method:'PUT'
        });
    },
    // 根据自提码查询详情
    exchangeCode(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/integral/integralOrder/exchangeCode/${params.code}`,
            params
        });
    },
    // 编辑活动规则
    updateSourceRule(data:{exchangeRules:any,forceProId:string}) {
        return service({
            url: `${BASE_URL}/api/admin/shop/plat/${data.forceProId}`,
            data,
            method:'PUT'
        });
    },
    // 查询规则
    getSourceRule(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/shop/plat/list`,
            params
            
        });
    },
     // 设置IP娃娃
     ipDoll(data:any) {
        return service({
            url: `${BASE_URL}/api/admin/force/forceProduct/authentication/${data.id}`,
            data,
            method:'PUT'
        });
    },
   
}
export default sourceExchangeAPI;