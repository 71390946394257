import { service, BASE_URL } from "../request";
const withdrawalAPI = {
    /* 查询详情根据ID提现记录 */
    getWithdrawalById(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/withdrawal/withdrawal/${params.id}`,
            params
        });
    },
    /* 查询提现记录列表 */
    getWithdrawalList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/withdrawal/withdrawal/list`,
            params
        });
    },
    /* 审批现金提现*/
    withdrawal(data: any) {
        return service({
            url: BASE_URL + `/api/admin/withdrawal/withdrawal/approval/${data.id}`,
            method: "PUT",
            data
        });
    },

    
}
export default withdrawalAPI;