import { service, BASE_URL, mapBaseURL } from "../request";
const SettingApi = {
    getPlatList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/shop/plat/list`,
            params
        });
    },

    /* 新增 */
    addPlatData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/shop/plat`,
            method: 'POST',
            data
        });
    },
    /* 编辑 */
    upPlatData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/shop/plat/${data.id}`,
            method: 'PUT',
            data
        });
    },
}
export default SettingApi;