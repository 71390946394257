import GoodsOrderApi from "@/api/modules/goodsOrder";
import { Button, Image, Spin, Table, TableColumnsType } from "antd";
import { Key } from "react";
import "./table.less";
type Props = {
  isModalVisible: boolean;
  showModal: Function;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = (obj: { id: string }) => {
    props.showModal({ ...obj, look: false });
  };
  const editLookData = async (obj: { id: string }, look: boolean) => {
    console.log(obj);
    const res: any = await GoodsOrderApi.getOrderByIdInfo(obj);
    console.log(res, "resss");

    props.showModal({ ...res.data, look });
  };
  const stateText = (t: any) => {
    switch (t) {
      case 1:
        return "待支付";

      case 2:
        return "待发货";
      case 3:
        return "待收货";
      case 5:
        return "已完成";

      case 6:
        return "已取消";
      case 7:
        return "售后中";
      case 8:
        return "售后完成";
      default:
        break;
    }
  };
  const columns: TableColumnsType<any> = [
    { title: "订单编号", dataIndex: "commodityOrderNo", key: "id" },
    { title: "客户名称", dataIndex: "userName", key: "id" },
    { title: "联系电话", dataIndex: "userPhone", key: "id" },
    { title: "商品", dataIndex: "commodityName", key: "id" },
    { title: "规格", dataIndex: "commoditySpecification", key: "id" },

    {
      title: "数量",
      dataIndex: "num",
      key: "id",
      render: (_: any, record: any) => <span>x{record.num}</span>,
    },
    { title: "实付金额", dataIndex: "price", key: "id" },
    { title: "创建时间", dataIndex: "createTime", key: "id" },
    { title: "物流单号", dataIndex: "nogisticsNo", key: "id" },
    {
      title: "状态",
      dataIndex: "conponType",
      key: "id",
      render: (_: any, record: any) => (
        <span>
          {/*     {record.state === 0
            ? "待支付"
            : record.state === 1
            ? "已支付"
            : record.state === 2
            ? "已取消"
            : "已退款"} */}
          {stateText(record.state)}
        </span>
      ),
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editLookData(record, true);
            }}
          >
            详情
          </Button>
          {record.state === 2 ? (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                editLookData(record, false);
              }}
            >
              发货
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;
