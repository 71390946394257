import {
  Button,
  Spin,
  Table,
  TableColumnsType,
  Drawer,
  Tree,
  Space,
  message,
  Popconfirm,
} from "antd";
import { Key, useEffect, useState } from "react";
import type { DataNode } from "antd/es/tree";
import SystemApi from "@/api/modules/system";
import "./table.less";
type Props = {
  isModalVisible: boolean;
  showModal: Function;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
};

const TableList = (props: Props) => {
  const { total, page, setPage } = props;
  const [visible, setVisible] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [menListData, setMenuListData] = useState<Array<object | any>>([]);
  const [menListIds, setMenuListIds] = useState<Array<object | any>>([]);
  const [menuIds, setMenuIds] = useState<Array<object | any>>([]);
  const [roleId, setRoleId] = useState<String>("");

  const showDrawer = async (record: { id: string }) => {
    const res = await SystemApi.getRoleMenuDataList({ id: record.id });
    setVisible(true);
    let arr = res.data.filter((item: any) => item.root === 2);
    let ids = arr.map((item: any) => item.id);
    let meuIds = res.data.map((item: any) => item.id);
    setCheckedKeys([...ids]);
    setMenuIds([...meuIds]);
    setRoleId(record.id);
  };

  const onClose = () => {
    setMenuIds([]);
    setRoleId("");
    setCheckedKeys([]);
    setVisible(false);
  };
  /* 展开收起节点 */
  const onExpand = (expandedKeysValue: React.Key[]) => {
    console.log("onExpand", expandedKeysValue);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };
  /* 点击复选框触发 */
  const onCheck = (checkedKeysValue: any, e: any) => {
    console.log(e);

    let concatTreeData = checkedKeysValue.concat(e.halfCheckedKeys);

    console.log(concatTreeData, "勾选所有节点");
    setMenuIds([...concatTreeData]);
    /*  console.log("onCheck", checkedKeysValue); */
    setCheckedKeys(checkedKeysValue);
  };
  /* 点击树节点触发 */
  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    console.log("onSelect", info);
    setSelectedKeys(selectedKeysValue);
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  /* 获取菜单列表 */
  const getAllMenuListData = async () => {
    const res: any = await SystemApi.getMenuList({ isTree: true });
    if (res.code === 200) {
  /*     let arr = res.data.filter(
        (item: { name: string }) => item.name === "商家联盟菜单"
      ); */
      console.log(res.data[0]?.menus);

      setMenuListData([...res.data]);
    }
  };

  const editData = async (obj: { id: string }) => {
    const res: any = await SystemApi.getRoleMenuDataList(obj);
    let menuIds = res.data.map((item: any) => item.id);
    props.showModal({ ...obj, menuIds });
  };
  /* 给角色关联菜单 */
  const addRoleMenuDataList = async () => {
    const res: any = await SystemApi.addRoleMenuDataList({
      roleId,
      menuIds,
    });
    if (res.code === 200) {
      message.success("编辑成功");
      onClose();
    }
  };/* 删除角色 */
  const deleteAdminRole = async (data: { id: string }) => {
    const res: any = await SystemApi.deleteRoleData(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };
  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  useEffect(() => {
    getAllMenuListData();
  }, []);
  const columns: TableColumnsType<any> = [
    { title: "角色昵称", dataIndex: "name", key: "id" },
    { title: "创建时间", dataIndex: "createTime", key: "id" },
    { title: "描述", dataIndex: "desc", key: "id" },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editData(record);
            }}
          >
            编辑角色
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              showDrawer(record);
            }}
          >
            关联菜单
          </Button>
          <Popconfirm
            title="确定删除角色吗？"
            onConfirm={() => {
              deleteAdminRole(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
      <Drawer
        title="菜单权限"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          fieldNames={{
            title: "name",
            key: "id",
            children: "menus",
          }}
          treeData={menListData}
        />

        <div className="tree_but_box">
          <Button
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            取消
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              addRoleMenuDataList();
            }}
          >
            确定
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default TableList;
