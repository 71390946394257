import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from "react-router-dom"
const ErrorDom = () => {
    const navigate = useNavigate();

    const linkLogin = () => {
        sessionStorage.clear()
        navigate('/')
    }
    return (
        <Result
            status="404"
            title="404"
            subTitle="页面走丢了"
            extra={<Button type="primary" onClick={() => { linkLogin() }}>返回登录~~</Button>}
        />
    )
};

export default ErrorDom;