import TableList from "./components/Table";
import Popup from "./components/popup";
import { Button, Input, Select, Space } from "antd";
import { useState, useEffect, useRef } from "react";
import userAPI from "@/api/modules/user";
import withdrawalAPI from "@/api/modules/withdrawal"
import Dict from "@/api/modules/dict";
import "./index.less";
type Props = {};
const Examine = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>("");
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const conponNameRef = useRef<any>();
  const conponPhoneRef = useRef<any>();
  const [page, setListPage] = useState<any>({
    current: 1,
    size: 10,
    nickName: "",
    stuPhone: "",
    stuState:"",
  });
  const { Option } = Select;
  /* 存储适用类型字典 */
  const [applicableDict, setApplicableDict] = useState<any>([]);
  /* 存储 券类型*/
  const [couponsDict, setCouponsDict] = useState<any>([]);
  const [conponState, setConponState] = useState<number|string>("");
  const [lableDict, setLableDict] = useState<any>([]);
  useEffect(() => {
    getListData();
  }, [page]);
  /* 获取审核列表 */
  const getListData = async () => {
    setLoading(true);
    const res: any = await withdrawalAPI.getWithdrawalList(page);
    console.log(res, "审核列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: Object) => {
    setRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setRecord("");
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setRecord("");
    setIsModalVisible(false);
    getListData();
  };
  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      nikeName: conponNameRef.current.input.value || "",
      stuPhone: conponPhoneRef.current.input.value || "",
      stuState: conponState === 1 || conponState===2||conponState===3? conponState : "",
    };
    console.log(obj);

    setListPage({ ...obj });
  };
  /* 获取字典 */
  const getDictList = async () => {
    const res: any = await Dict.getDictionaryList({});
    console.log(res, "字典");
    if (res.code === 200) {
      const APPLICABLE_TYPE: any = res.data.filter((item: any) => {
        return item.code === "APPLICABLE_TYPE";
      })[0];
      const CONPON_TYPE: any = res.data.filter((item: any) => {
        return item.code === "CONPON_TYPE";
      })[0];

      const LABLE_TYPE: any = res.data.filter((item: any) => {
        return item.code === "LABLE_TYPE";
      })[0];
      setLableDict([...LABLE_TYPE.dictList]);
      setApplicableDict([...APPLICABLE_TYPE.dictList]);
      setCouponsDict([...CONPON_TYPE.dictList]);
    }
  };

  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };

  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="用户昵称" ref={conponNameRef} allowClear />
        <Input placeholder="联系电话" ref={conponPhoneRef} allowClear />

        <Select placeholder="状态" onChange={changeConponState} allowClear>
          <Option value={1}>未审核</Option>
          <Option value={2}>通过</Option>
          <Option value={3}>未通过</Option>
        </Select>
        <Button onClick={searchInPut} size="middle">
          查询
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          showModal={showModal}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
          applicableDict={applicableDict}
          couponsDict={couponsDict}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible}
        record={record}
        applicableDict={applicableDict}
        couponsDict={couponsDict}
        lableDict={lableDict}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
        listData={listData}
      ></Popup>
    </div>
  );
};

export default Examine;
