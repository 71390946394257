import TableList from "./components/Table";
import Popup from "./components/popup";
import { Button, Input, Select, Space } from "antd";
import { useState, useEffect, useRef, Key } from "react";
import GoodsOrderApi from "@/api/modules/goodsOrder";
import "./index.less";
import { OrderList } from "@/utils/tsModules/goods";
type Props = {};
const Aftersales = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>("");
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [page, setListPage] = useState<any>({
    current: 1,
    size: 10,
    state: 6,
  });
  const { Option } = Select;
  const [conponState, setConponState] = useState<number | string>("");
  useEffect(() => {
    getListData();
  }, [page]);
  /* 获取订单列表 */
  const getListData = async () => {
    setLoading(true);
    const res: any = await GoodsOrderApi.getAfterOrder(page);
    console.log(res, "订单列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: Object) => {
    setRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setRecord("");
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setRecord("");
    setIsModalVisible(false);
    getListData();
  };
  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      state: conponState === 0 || conponState ? conponState : "",
    };
    console.log(obj);

    setListPage({ ...obj });
  };

  const changeConponState = (e: any) => {
    setConponState(e);
  };
  const [seletData, setSeletData] = useState<any>([

    { state: 7, name: "售后中" },
    { state: 8, name: "售后完成" },
  
  ]);
  const options = seletData.map(
    (item: { state: Key | null | undefined; name: string }) => (
      <Option key={item.state} value={item.state}>
        {item.name}
      </Option>
    )
  );
  return (
    <div>
      <Space size={20} className="Space_box" >
 {/*        <Input placeholder="订单编号" ref={conponPhoneRef} allowClear />
        <Input placeholder="用户昵称" ref={conponNameRef} allowClear />
 */}
        <Select placeholder="状态筛选" onChange={changeConponState} allowClear>
          {options}
        </Select>
        <Button onClick={searchInPut} size="middle">
          查询
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          showModal={showModal}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible}
        record={record}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
      ></Popup>
    </div>
  );
};

export default Aftersales;
