import { service, BASE_URL, } from "../request";
import { ListData } from "@/utils/tsModules/banner";
import { updateStatement } from "typescript";
const BannerAPi = {
    /* 获取轮播图列表 */
    getBannerList(params: ListData) {
        return service({
            url: BASE_URL + '/api/admin/xj/banner/list',
            params
        });
    },
    /* 添加轮播图 */
    addBannerData(data: any) {
        return service({
            url: BASE_URL + '/api/admin/xj/banner',
            method: 'POST',
            data
        });
    },
    /* 删除轮播图 */
    deleteBannerById(data: { id: string }) {
        return service({
            url: BASE_URL + `/api/admin/xj/banner/${data.id}`,
            method: 'DELETE',
        });
    },
    /* 轮播上下架
     */
    updateStatement(params: { state: number, id: string }) {
        return service({
            url: BASE_URL + `/api/admin/xj/banner/updateState`,
            method: 'PUT',
            params
        });
    },
    /* 根据id 查询详情 */
    getBannerDataById(data: any) {
        return service({
            url: BASE_URL + `/api/admin/xj/banner/${data.id}`,

        });
    },
    /* bj */
    editBannerDataById(data: any) {
        return service({
            url: BASE_URL + `/api/admin/xj/banner/${data.id}`,
            method: 'PUT',
            data
        });
    },
    // 字典管理
    findByCode(params: any) {
        return service({
            url: BASE_URL + '/api/admin/dictionary/list/category',
            params
        });
    },
	

}
export default BannerAPi;