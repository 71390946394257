import TableList from "./components/Table";
import Popup from "./components/popup";
import { Button, Input, Select, Space } from "antd";
import { useState, useEffect, useRef } from "react";
import SystemApi from "@/api/modules/system";
import "./index.less";
type Props = {};
const Role = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>("");
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [page, setListPage] = useState<any>({
    current: 1,
    size: 10,
  });
  useEffect(() => {
    getListData();
  }, [page]);
  /* 获取订单列表 */
  const getListData = async () => {
    setLoading(true);
    const res: any = await SystemApi.getRoleList(page);
    console.log(res, "角色列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: Object) => {
    setRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setRecord("");
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setRecord("");
    setIsModalVisible(false);
    getListData();
  };

  const changeConponState = () => {
    setRecord("");
    setIsModalVisible(true);
  };

  return (
    <div>
      <Space size={20} className="Space_box">
        <Button onClick={changeConponState}   type="primary" size="middle">
          新增
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          showModal={showModal}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible}
        record={record}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
      ></Popup>
    </div>
  );
};

export default Role;
