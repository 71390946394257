import SelfLiftingAPI from "@/api/modules/selfLifting";
import { ListData } from "@/utils/tsModules/banner";
import { Button, Space } from "antd";
import { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import TableList from "./components/Table";
import Popup from "./components/popup";
import "./index.less";
const Banner = () => {
  const handleCancel = () => {
    setRecord("");
    setIsModalVisible(false);
  };

  const [record, setRecord] = useState<any>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const conponNameRef = useRef<any>();
  // const navigate = useNavigate();
  const [page, setListPage] = useState<ListData>({
    current: 1,
    size: 10,
    name: "",
  });
  useEffect(() => {
    getListData();
  }, [page]);

  const getListData = async () => {
    setLoading(true);
    const res: any = await SelfLiftingAPI.getAddressList(page);
    console.log(res, "自提点的列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: Object) => {
    // navigate("/home/banner/edit");
    // 弹出新增窗口
    setRecord(record);
    setIsModalVisible(true);
  };

  const handleSuccess = () => {
    setIsModalVisible(false);
    getListData();
  };
  /* 搜索 */

  // const searchInPut = () => {
  //   let obj = {
  //     current: 1,
  //     size: 10,
  //     name: conponNameRef.current.input.value,
  //   };
  //   console.log(obj);

  //   setListPage({ ...obj });
  // };

  return (
    <div>
      <Space size={20} className="Space_box">
        <Button
          type="primary"
          onClick={() => {
            showModal("");
          }}
          size="middle"
         
        >
          增加自提点
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible}
        record={record}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
        listData={listData}
      ></Popup>
    </div>
  );
};

export default Banner;
