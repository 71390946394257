import { Button, Form, Input, message, Modal } from "antd";
import { useRef, useState, useEffect } from "react";
import GoodsApi from "@/api/modules/class";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
  handleSuccess: any;
};

const Popup = (props: Props) => {
  let [loading, setLoading] = useState<boolean>(false);
  const addFrom: any = useRef();
  useEffect(() => {
    if (props.record.id) {
    setTimeout(() => {
      addFrom.current.setFieldsValue({
        ...props.record,
      });
    },0);
    }
  }, [props.record]);

  /* 表单提交  同时获取逆地址编码*/
  const onFinish = async (values: any) => {
    let obj = {
      ...values,
    };
     setLoading(true);
    if (props.record.id) {
      const req: any = await GoodsApi.updeatClassById({
        id: props.record.id,
        ...obj,
      });
      if (req.code === 200) {
     
        message.success("编辑成功");
        removeForm();
        props.handleSuccess();
        
      }
    } else {
      console.log("Success:", obj);
      const req: any = await GoodsApi.addClassData(obj);
      if (req.code === 200) {
       
        message.success("添加成功");
        removeForm();
        props.handleSuccess();
       
      }
    }
    setLoading(false);
  };

  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={props.record ? "编辑分类" : "新增分类"}
      maskClosable={false}
      width={600}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      onCancel={handleCancel}
      footer={false}
      okText="确认"
      cancelText="取消"
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        <Form.Item
          label="分类名称"
          name="sortName"
          rules={[{ required: true, message: "请输入分类名称" }]}
        >
          <Input placeholder="请输入分类名称" />
        </Form.Item>

        <Form.Item
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          label="分类描述"
          name="sortInfo"
          rules={[{ required: true, message: "请输入分类描述" }]}
        >
          <Input.TextArea
            style={{ height: 120 }}
            maxLength={250}
            placeholder="请输入分类描述"
          />
        </Form.Item>
        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Popup;
