import TableList from "./components/Table";
import Popup from "./components/popup";
import { Button, Input, Select, Space } from "antd";
import { useState, useEffect, useRef } from "react";
import GoodsOrderApi from "@/api/modules/goodsOrder";
import "./index.less";
import { OrderList } from "@/utils/tsModules/goods";
type Props = {};
const GoodsOrder = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>("");
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const conponNameRef = useRef<any>();
  const conponPhoneRef = useRef<any>();
  const [page, setListPage] = useState<OrderList>({
    current: 1,
    size: 10,
    userName: "",
    commodityOrderNo: "",
    state: "",
  });
  const { Option } = Select;
  const [conponState, setConponState] = useState<number | string>("");
  useEffect(() => {
    getListData();
  }, [page]);
  /* 获取订单列表 */
  const getListData = async () => {
    setLoading(true);
    const res: any = await GoodsOrderApi.getList(page);
    console.log(res, "订单列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: Object) => {
    setRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setRecord("");
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setRecord("");
    setIsModalVisible(false);
    getListData();
  };
  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      userName: conponNameRef.current.input.value || "",
      commodityOrderNo: conponPhoneRef.current.input.value || "",
      state: conponState === 0 || conponState ? conponState : "",
    };
    console.log(obj);

    setListPage({ ...obj });
  };

  const changeConponState = (e: any) => {
    setConponState(e);
  };

  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="订单编号" ref={conponPhoneRef} allowClear />
        <Input placeholder="用户昵称" ref={conponNameRef} allowClear />

        <Select placeholder="状态" onChange={changeConponState} allowClear>
          <Option key="1" value={1}>
            待支付
          </Option>
          <Option key="2" value={2}>
            待发货
          </Option>
          <Option key="3" value={3}>
            待收货
          </Option>
          <Option key="5" value={5}>
            已完成
          </Option>
          <Option key="6" value={6}>
            已取消
          </Option>
          <Option key="7" value={7}>
            售后中
          </Option>
          <Option key="8" value={8}>
            已退款
          </Option>
        </Select>
        <Button onClick={searchInPut} size="middle">
          查询
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          showModal={showModal}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible}
        record={record}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
      ></Popup>
    </div>
  );
};

export default GoodsOrder;
