import TableList from "./components/Table";

import Dict from "@/api/modules/dict";
import OrderAPI from "@/api/modules/order";
import { Button, Input, Select, Space } from "antd";
import { Key, useEffect, useRef, useState } from "react";
import "./index.less";
type Props = {};
const Order = (props: Props) => {
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const userNameRef = useRef<any>();
  const userPhoneRef = useRef<any>();
  const orderNoRef = useRef<any>();
  const [page, setListPage] = useState<any>({
    current: 1,
    size: 10,
    couponId: "",
    orderNo: "",
    state: "",
    userName: "",
    userPhone: "",
  });
  const { Option } = Select;
  /* 存储 券类型*/
  const [couponsDict, setCouponsDict] = useState<any>([]);
  const [conponState, setConponState] = useState<any>("");
  useEffect(() => {
    getListData();
  }, [page]);
  useEffect(() => {
    /* 获取适用类型 */
    getDictList();
  }, []);
  /* 获取字典 */
  const getDictList = async () => {
    const res: any = await Dict.getDictionaryList({});
    console.log(res, "字典");
    if (res.code === 200) {
      const CONPON_TYPE: any = res.data.filter((item: any) => {
        return item.code === "CONPON_TYPE";
      })[0];
      setCouponsDict([...CONPON_TYPE.dictList]);
    }
  };
  const getListData = async () => {
    setLoading(true);
    const res: any = await OrderAPI.getCouponList(page);
    console.log(res, "优惠券的列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      couponId: orderNoRef.current.input.value,
      userName: userNameRef.current.input.value,
      userPhone: userPhoneRef.current.input.value,
      state: conponState || conponState === 0 ? conponState : "",
    };
    console.log(obj);

    setListPage({ ...obj });
  };

  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };

  const [seletData, setSeletData] = useState<any>([
    { state: 0, name: "待支付" },
    { state: 1, name: "已支付" },
    { state: 2, name: "已取消" },
    { state: 3, name: "已退款" },
    { state: 4, name: "已过期" },
  ]);
  const options = seletData.map(
    (item: { state: Key | null | undefined; name: string }) => (
      <Option key={item.state} value={item.state}>
        {item.name}
      </Option>
    )
  );
  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="订单编号" ref={orderNoRef} allowClear />
        <Input placeholder="用户姓名" ref={userNameRef} allowClear />
        <Input placeholder="联系电话" ref={userPhoneRef} allowClear />

        <Select placeholder="订单状态" onChange={changeConponState} allowClear>
          {options}
        </Select>
        <Button type="primary" onClick={searchInPut} size="middle">
          查询
        </Button>
        {/*         <Button
          type="primary"
          onClick={() => {
            showModal("");
          }}
          size="middle"
        >
          新增
        </Button> */}
      </Space>
      {
        <TableList
          setListData={setListData}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
          couponsDict={couponsDict}
        ></TableList>
      }
    </div>
  );
};

export default Order;
