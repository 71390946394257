import { service, BASE_URL } from "../request";
import { OrderList } from "@/utils/tsModules/goods";
const GoodsOrderApi = {
    /* 获取列表 */
    getList(params: OrderList) {
        return service({
            url: `${BASE_URL}/api/admin/commodity/commodityOrderSub/list`,
            params
        });
    },
    /* 通过id 查询订单详情 */
    getOrderByIdInfo(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/commodity/commodityOrderSub/${params.id}`,
            params
        });
    },
    /* 发货 */
    putCommodityOrderSub(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/commodity/commodityOrderSub/${data.id}`,
            method:'PUT',
            data
        });
    },
    /* 获取售后订单列表 */
    getAfterOrder(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/order/afterOrder/list`,
            params
        });
    },
    /* 退款审核 */
    platAuditRefund(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/order/afterOrder/platAuditRefund`,
            method:'PUT',
            params
        });
    },
}
export default GoodsOrderApi;