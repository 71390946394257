// import BannerAPI from "@/api/modules/banner";
import interestRateApi from "@/api/modules/interestRate";
import { ListData } from "@/utils/tsModules/banner";
import { Button, Input, message, Space,InputNumber } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableList from "./components/Table";
import "./index.less";
const Banner = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [listData, setListData] = useState<any>("");
  const [typeName, setTypeName] = useState<any>("优惠券分享");
  const [type, setType] = useState<any>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [vipUserCommissionValue, setVipUserCommissionValue] = useState<any>('');
  const [userCommissionValue, setUserCommissionValue] = useState<any>('');
  // const vipUserCommissionValue = useRef<any>();
  // const userCommissionValue = useRef<any>();
  const navigate = useNavigate();
  const [page, setListPage] = useState<ListData>({
    current: 1,
    size: 10,
    name: "",
  });
  useEffect(() => {
    getListData2();
  }, [page]);
// 进入页面默认获取列表
  // const getListData = async () => {
  //   setLoading(true);
  //   const res: any = await interestRateApi.getRateList(page);
  //   // console.log(res, "利率列表");
  //   if (res.code === 200) {
  //     setLoading(false);
  //     setListData([...res.data.records]);
  //     setTotal(res.data.total || 0);
  //   }
  // };
  // 获取不同类型的列表
  const getListData2 = async () => {
    setLoading(true);
    const res: any = await interestRateApi.getRateList({...page,interestRateType:type});
    // console.log(res, "利率列表牛啊");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    } 
  };
// 点击确认
  const confirm = async() => {
        if(vipUserCommissionValue||userCommissionValue){
          const res:any=await interestRateApi.updateRateById({userCommissionValue:userCommissionValue,
            vipUserCommissionValue:vipUserCommissionValue,interestRateType:type})
           
            
            if(res.data){
            message.success('修改成功');
            getListData2();
            setVipUserCommissionValue('')
            setUserCommissionValue('')
            }else{
              // setVipUserCommissionValue('')
              // setUserCommissionValue('')
              message.error(res.msg);
            }
        }else{
          message.error('请填写佣金率');
        }
       
     
      
   
      
    // navigate("/home/banner/edit");
  };
  const typeSwitching = (name:number) => {
    switch (name) {
      case 0:
        setType(0)
        setTypeName('优惠券分享')
        // console.log(type,'我是优惠券分享');
        // getListData2();
        break;
      case 1:
        setType(1)
        setTypeName('商品分享')
        // console.log(type,'我是优惠券分享');
        // getListData2();
        break;
      case 2:
        setType(2)
        setTypeName('购买商品')
        // console.log(type,'我是优惠券分享');
        // getListData2();
        break;
      case 3:
        setType(3)
        setTypeName('购买优惠券')
        // console.log(type,'我是购买优惠券');
        // getListData2();
        break;
    
      default:
        break;
    }
  };
  useEffect(() => {
    getListData2();
  }, [type])
  
  const handleSuccess = () => {
    setIsModalVisible(false);
    getListData2();
  };
  /* 搜索 */

  // const searchInPut = () => {
  //   let obj = {
  //     current: 1,
  //     size: 10,
  //     name: conponNameRef.current.input.value,
  //   };
  //   console.log(obj);

  //   setListPage({ ...obj });
  // };

  return (
    <div>
      <Space size={20} className="Space_box">
        网格员：<InputNumber style={{width:'150px'}} max={100} min={1} placeholder="请设置佣金率（%)" value={vipUserCommissionValue} onChange={(value)=>{if(value){setVipUserCommissionValue(value)}else{setVipUserCommissionValue('')}
        }} />
        普通用户：<InputNumber style={{width:'150px'}} max={100} min={1} placeholder="请设置佣金率（%）" value={userCommissionValue} onChange={(value)=>{if(value){setUserCommissionValue(value)}else{setUserCommissionValue('')} 
        }} />
        <Button
          type="primary"
          onClick={() => {
            // showModal("");
            confirm()
          }}
          size="middle"
          
        >
          确定
        </Button>
      </Space>
      <br />
      <Space size={20} className="Space_box">
      <Button onClick={()=>{typeSwitching(0)}} type="primary">优惠券分享</Button> 
      &nbsp;&nbsp;&nbsp;
      <Button  onClick={()=>{typeSwitching(1)}} type="primary">商品分享</Button> 
      &nbsp;&nbsp;&nbsp;
      <Button  onClick={()=>{typeSwitching(2)}} type="primary">购买商品</Button> 
      &nbsp;&nbsp;&nbsp;
      <Button  onClick={()=>{typeSwitching(3)}}  type="primary">购买优惠券</Button>
      </Space>
      
      {
        <TableList
          isModalVisible={isModalVisible}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
          typeName={typeName}
        ></TableList>
      }
    </div>
  );
};

export default Banner;
