import TableList from "./components/Table";

import Dict from "@/api/modules/dict";
import OrderAPI from "@/api/modules/order";
import { Button, Input, Select, Space, DatePicker } from "antd";
import { Key, useEffect, useRef, useState } from "react";
import locale from "antd/es/date-picker/locale/zh_CN";
import type { RangePickerProps } from "antd/es/date-picker";

import "./index.less";
type Props = {};
const WriteOff = (props: Props) => {
  const { RangePicker } = DatePicker;
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const userNameRef = useRef<any>();
  const userPhoneRef = useRef<any>();
  const shopNameRef = useRef<any>();
  const [page, setListPage] = useState<any>({
    current: 1,
    size: 10,
    shopName: "", //商家名称
    state: "",
    userName: "",
    userPhone: "",
    startTime: "", //'开始时间'
    endTime: "", //技术时间
  });

  const { Option } = Select;
  /* 存储 券类型*/
  const [couponsDict, setCouponsDict] = useState<any>([]);
  const [conponState, setConponState] = useState<any>("");
  /* 存储时间 */
  const [timData, setTime] = useState<any>([]);
  useEffect(() => {
    getListData();
  }, [page]);
  useEffect(() => {
    /* 获取适用类型 */
    getDictList();
  }, []);
  /* 获取字典 */
  const getDictList = async () => {
    const res: any = await Dict.getDictionaryList({});
    console.log(res, "字典");
    if (res.code === 200) {
      const CONPON_TYPE: any = res.data.filter((item: any) => {
        return item.code === "CONPON_TYPE";
      })[0];
      setCouponsDict([...CONPON_TYPE.dictList]);
    }
  };
  const getListData = async () => {
    setLoading(true);
    const res: any = await OrderAPI.getOrderWriteOff(page);
    console.log(res, "优惠券的列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };
  /*    获取下载链接 */
  const getExportExecl = async () => {
    const res:any = await OrderAPI.getExportExecl({});
    var blob = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    var objectUrl = URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
 /*    a.style = "display: none"; */
    a.href = objectUrl;
    a.download = '人员列表';
    a.click();
    document.body.removeChild(a);


  };
  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      shopName: shopNameRef.current.input.value || "",
      userName: userNameRef.current.input.value || "",
      userPhone: userPhoneRef.current.input.value || "",
      state: conponState || conponState === 0 ? conponState : "",
      endTime: timData[1] || "",
      startTime: timData[0] || "",
    };
    console.log(obj);

    setListPage({ ...obj });
  };
  const onchangeTime = (e: any) => {
    let arr = e.map((item: { _d: any }) => {
      let dateee = new Date(item._d).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "")
        .split(" ")[0];
    });
    console.log(arr);
    setTime([...arr]);
  };
  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };

  const [seletData, setSeletData] = useState<any>([
    { state: 0, name: "已核销" },
    { state: 1, name: "未核销" },
  ]);
  const options = seletData.map(
    (item: { state: Key | null | undefined; name: string }) => (
      <Option key={item.state} value={item.state}>
        {item.name}
      </Option>
    )
  );
  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="商品名称" ref={shopNameRef} allowClear />
        <Input placeholder="用户姓名" ref={userNameRef} allowClear />
        <Input placeholder="联系电话" ref={userPhoneRef} allowClear />

        <Select placeholder="订单状态" onChange={changeConponState} allowClear>
          {options}
        </Select>
        <RangePicker
          locale={locale}
          onChange={onchangeTime}
          placeholder={["开始时间", "结束时间"]}
        />
        <Button type="primary" onClick={searchInPut} size="middle">
          查询
        </Button>
        {<Button size="middle" onClick={getExportExecl}>导出</Button>}
      </Space>
      {
        <TableList
          setListData={setListData}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
          couponsDict={couponsDict}
        ></TableList>
      }
    </div>
  );
};

export default WriteOff;
