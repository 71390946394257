import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
} from "antd";
import { Table } from "antd";
import "./table.less";
import React, { Key, useEffect, useState } from "react";
import BusinessAPI from "@/api/modules/business";
interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  showModal: Function;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;
  const onSwitchChange = async (checked: number, event: any) => {
    console.log(checked, event);
    if (checked) {
      const res: any = await BusinessAPI.onlineBusinessData({
        id: event.id,
      });
      if (res.code === 200) {
        message.success("上架成功");
        props.handleSuccess();
      } else {
        props.handleSuccess();
      }
    } else {
      const res: any = await BusinessAPI.offlineBusinessData({
        id: event.id,
      });
      if (res.code === 200) {
        message.success("下架成功");
        props.handleSuccess();
      } else {
        props.handleSuccess();
      }
    }
  };
  const deleteDictById = async (data: { id: string }) => {
    console.log(data.id);
    const res: any = await BusinessAPI.deleteBusinessData(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };

  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const columns: TableColumnsType<DataType> = [
    { title: "商户名称", dataIndex: "shopName", key: "id" },
    { title: "负责人", dataIndex: "shopCadre", key: "id" },
    { title: "联系电话", dataIndex: "contactPhone", key: "id" },
    { title: "账号", dataIndex: "shopUsername", key: "id" },
    { title: "商家地址", dataIndex: "address", key: "id" },
    {
      title: "状态",
      dataIndex: "state",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            {record.state === 0 || record.state === 1 ? (
              <Switch
                checkedChildren="上架"
                unCheckedChildren="下架"
                defaultChecked={record.state === 0}
                key={record.id}
                onChange={() => {
                  onSwitchChange(record.state, record);
                }}
              />
            ) : (
              <span key={record.id}>
                {record.state === 2 ? "待认证" : "认证不通过"}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              props.showModal({ ...record });
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定删除商户吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
