import GoodsOrderApi from "@/api/modules/goodsOrder";
import userAPI from "@/api/modules/user";
import type { RadioChangeEvent } from "antd";
import { Button, Card, Form, message, Modal, Radio } from "antd";
import "moment/locale/zh-cn";
import { useEffect, useRef, useState } from "react";
import "../index.less";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  handleSuccess: any;
};

const Popup = (props: Props) => {
  const { record } = props;
  let [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState(7);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const addFrom: any = useRef();
  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const handleErrar = async () => {
    setLoading(true);
    const res: any = await GoodsOrderApi.platAuditRefund({
      id: props.record.id,
      state: value,
    });
    if (res.code === 200) {
      message.success("审核完成");
      props.handleSuccess();
    }
    setLoading(false);
  };
  return (
    <Modal
      title={record.look ? "详情" : "审核"}
      maskClosable={false}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      width={800}
      onCancel={handleCancel}
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        size="large"
      >
        <Form.Item>
          <Card style={{ width: "100%" }} hoverable>
         
            <p className="card_p_order">
              <span>退款金额：{record.refundFee}</span>
            </p>
            <p className="card_p_order">
              <span>申请原因：{record.reason}</span>
            </p>
            <p className="card_p_order">
              <span>
                退款类型：
                {record.refundType === 1
                  ? "退款"
                  : record.refundType === 2
                  ? "退货退款"
                  : record.refundType === 3
                  ? "换货"
                  : ""}
              </span>
            </p>
            {record.look ? null : (
              <div>
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={7}>通过</Radio>
                  <Radio value={10}>不通过</Radio>
                </Radio.Group>
              </div>
            )}
          </Card>
        </Form.Item>
        {record.look ? null : (
          <Form.Item>
            <div className="from_fh_but">
              <Button
                type="primary"
                size="middle"
                loading={loading}
                onClick={handleErrar}
              >
                确定
              </Button>
            </div>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default Popup;
