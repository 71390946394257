import { service, BASE_URL } from "../request";
const userAPI = {
    /* 获取用户列表 */
    getUserList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/user/userInfo/list`,
            params
        });
    },
    /* 删除用户 */
    deleteUserByIdData(data: any) {
        return service({
            url: BASE_URL + `/api/admin/user/userInfo/${data.id}`,
            method: "DELETE",
            data
        });
    },
    /* 用户启用禁用 */
    updateUserState(params: any) {
        return service({
            url: BASE_URL + `/api/admin/user/updateState/${params.id}`,
            method: "PUT",
            params
        });
    },
    /* 获取审核列表 */
    getExamineList(params: any) {
        return service({
            url: BASE_URL + `/api/admin/student/student/list`,
            params
        });
    },
    /* 审核通过 */
    putExamineAdopt(params: any) {
        return service({
            url: BASE_URL + `/api/admin/student/student/success/${params.id}/${params.stuRemarks}`,
            method: "PUT",
            params
        });
    },
    /* 不通过 */
    putExamineFailed(params: any) {
        return service({
            url: BASE_URL + `/api/admin/student/student/fail/${params.id}/${params.stuRemarks}`,
            method: "PUT",
            params
        });
    },
    // 网格员启用禁用
    updateMemberState(params: any) {
        return service({
            url: BASE_URL + `/api/admin/user/userInfo/mesh/${params.id}`,
            method: "PUT",
            params
        });
    },
    // 用户启用禁用
    updateUsersState(params: any) {
        return service({
            url: BASE_URL + `/api/admin/user/userInfo/status/${params.id}`,
            method: "PUT",
            params
        });
    },
}
export default userAPI;