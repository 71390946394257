import { Button, Form, Input, message, Modal, TreeSelect, Upload } from "antd";
import Dict from "@/api/modules/dict";
import React, { useRef, useState, useEffect } from "react";
import { BASE_URL } from "@/api/config";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
};
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Popup = (props: Props) => {
  const [fileList, setFileList] = useState<any>([]);
  let [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("0");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const addFrom: any = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.record.id) {
      console.log(props.record);
      
      setValue(props.record.pid);
      if (props.record.typeIcon) {
        let fileList = [
          {
            thumbUrl: props.record.typeIcon,
            name: "xxxx",
            response: {
              code: 200,
              data: props.record.typeIcon,
            },
          },
        ];
        setFileList(fileList);
      } else {
        setFileList([]);
      }
      setTimeout(()=>{
        addFrom.current.setFieldsValue({
          pid: props.record.pid,
          code: props.record.code,
          desc: props.record.desc,
          name: props.record.name,
          sort: props.record.sort,
        });
        },100);
      
    } /* else if(!props.record){
      addFrom.current.resetFields()
    } */
  }, [props.record]);

  const handleOk = () => {
    props.handleCancel();
  };
  const onChange = (newValue: string) => {
    setValue(newValue);
  };
  const onFinish = async (values: any) => {
    console.log(fileList);
    if (fileList.length) {
      values.typeIcon=fileList[0].response.data
    }
    setLoading(true);
    if (props.record.id) {
      updateDictData({ id: props.record.id, ...values });
    } else {
      values.sort = values.sort - 0;
      const res: any = await Dict.addDictionary(values);
      if (res.code === 200) {
      
        message.success("添加成功");
        addFrom.current.resetFields();
        handleOk();
      }
    }
    setLoading(false);
  };
  const updateDictData = async (data: any) => {
    const res: any = await Dict.updateDictData(data);
    if (res.code === 200) {
      setLoading(false);
      message.success("编辑成功");
      addFrom.current.resetFields();
      handleOk();
    }
  };
  const handleCancel = () => {
    addFrom.current.resetFields();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleCancelImg = () => setPreviewVisible(false);
  /* 图片上传 */
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    console.log(file.preview, "file2222");

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  /* 图片上传 */
  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
    event,
  }) => {
    console.log(file, "file");
    console.log(event, "event");
    console.log(newFileList, "fileList");
    setFileList(newFileList);
    /*     if (!file.response){
      message.error("文件过大,请重新上传");
    }
 */
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  return (
    <Modal
      title={props.record.id ? "编辑字典" : "新增字典"}
      maskClosable={false}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="确认"
      cancelText="取消"
      footer={null}
    >
      <Form
        ref={addFrom}
        initialValues={{
          desc: "",
          pid: "0",
        }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        {" "}
        <Form.Item
          label="字典名称"
          name="name"
          rules={[{ required: true, message: "请输入字典名称" }]}
        >
          <Input placeholder="请输入字典名称" />
        </Form.Item>
        <Form.Item
          label="图标"
          /* name="typeIcon" */
        >
          <Upload
            action={
              BASE_URL + "/api/open/aliOssFile/upload?filePath=image/&resType=1"
            }
            accept="image/png, image/jpeg"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            headers={{
              TOKEN: sessionStorage.getItem("token") || "",
            }}
            name="file"
            onChange={handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item
          label="字典code"
          name="code"
          rules={[{ required: true, message: "请输入字典code" }]}
        >
          <Input placeholder="请输入字典code" />
        </Form.Item>
        <Form.Item label="父级字典" name="pid">
          <TreeSelect
            placement="bottomLeft"
            style={{ width: "100%" }}
            value={value}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="选择字典"
            onChange={onChange}
            treeData={props.listData}
            treeDefaultExpandedKeys={[value]}
            fieldNames={{ label: "name", value: "id", children: "dictList" }}
          ></TreeSelect>
        </Form.Item>
        <Form.Item
          label="排序"
          name="sort"
          rules={[{ required: true, message: "请输入字典排序" }]}
        >
          <Input placeholder="请输入字典排序" />
        </Form.Item>
        <Form.Item label="字典描述" name="desc">
          <Input placeholder="字典描述" />
        </Form.Item>
        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>{" "}
    </Modal>
  );
};

export default Popup;
