
import { Button, message, Popconfirm, Spin, Switch, Table, TableColumnsType } from "antd";
import { Key } from "react";
import userAPI from "@/api/modules/wxuser";
import "./table.less";
interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  setListData: Function;
  listData: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
  getListData: Function
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;

  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  /* 启用禁用上下架 */
  const onSwitchChange = async (e: any, record: any) => {
    const res: any = await userAPI.updateMemberState({
      id: record.id,
    });
    if (res.code === 200) {
      setPage({
        ...props.page,
      });
      message.success("设置成功");
    }
    console.log(e);
  };
  /* 启用禁用上下架 */
  const onSwitchChange2 = async (e: any, record: any) => {
    const res: any = await userAPI.updateUsersState({
      id: record.id,
    });
    if (res.code === 200) {
      setPage({
        ...props.page,
      });
      message.success("设置成功");
    }
    console.log(e);
  };
  const deleteAdminUser = async (data: { id: string }) => {
    const res: any = await userAPI.deleteUserByIdData(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.getListData();
    }
  };
  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: "头像",
      dataIndex: "fullName",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <img src={record.avatar} alt="" style={{ width: 40, height: 40 }} />
        );
      },
    },
    { title: "用户昵称", dataIndex: "nickName", key: "id" },
    { title: "联系电话", dataIndex: "phone", key: "userPhone" },
    {
      title: "是否认证",
      dataIndex: "phone",
      key: "id",
      render: (_: any, record: any) => (
        <span>{record.auth_state === 1 ? "已认证" : "未认证"}</span>
      ),
    },
    { title: "注册时间", dataIndex: "createTime", key: "id" },
    { title: "最后登录时间", dataIndex: "lastLoginTime", key: "id" },
    { title: "源力值(个)", dataIndex: "forceNum", key: "id" },
    {
      title: "用户类型", dataIndex: "position", key: "id",
      render: (_: any, record: any) => (
        <div className="button_box">
          {record.position === 0 ? '普通用户' : '网格员'}
        </div>
      ),
    },
   
      {
      title: "设置成为网格员",
      dataIndex: "position",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            <Switch
              defaultChecked={record.position === 1}
              key={record.id}
              onChange={() => {
                onSwitchChange(record.state, record);
              }}
            />
          </div>
        );
      },
    },
      {
      title: "状态",
      dataIndex: "status",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            <Switch
              defaultChecked={record.status === 0}
              key={record.id}
              onChange={() => {
                onSwitchChange2(record.status, record);
              }}
            />
          </div>
        );
      },
    },
      
  
  {
    title: "操作",
    key: "operation",
    render: (_: any, record: any) => (
      <div className="button_box">
        <Popconfirm
          title="确定删除用户吗，此操作不可逆？"
          onConfirm={() => {
            deleteAdminUser(record);
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" danger size="small">
            删除
          </Button>
        </Popconfirm>
      </div>
    ),
    },
  ];

return (
  <div>
    <Spin spinning={props.loading} size="large">
      <Table
        scroll={{ y: "55vh" }}
        rowKey={(record) => record.id}
        columns={columns}
        expandable={{ childrenColumnName: "dictList" }}
        dataSource={props.listData}
        pagination={{
          position: ["bottomRight"],
          pageSize: page.size,
          current: page.current,
          total: total,
          onChange: pageonChange,
        }}
      />
    </Spin>
  </div>
);
};

export default TableList;
