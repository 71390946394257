import {service,BASE_URL} from "../request";
/* 发起登录 */
type Props = {
    password :string,
    username:string
}
export const getLogin = (params: Props) => {
    return service({
        url: BASE_URL+`/api/admin/user/login`,
        method: "POST",
        params
    });
}

