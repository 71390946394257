import { service, BASE_URL, mapBaseURL,mapService } from "../request";
import { GetList } from "@/utils/tsModules/buiness";
const BusinessAPI = {
    getBusinessList(params: GetList) {
        return service({
            url: `${BASE_URL}/api/admin/shop/shop/list`,
            params
        });
    },
    /* 获取腾讯地图行政区 */
    getMapDistrict(params: any) {
        return mapService({
            url: `${mapBaseURL}/ws/district/v1/getchildren`,
            params
        });
    },
    /* 腾讯地图逆地址解析 */
    getMapGeocoder(params: any) {
        return mapService({
            url: `${mapBaseURL}/ws/geocoder/v1/`,
            params
        });
    },
    /* 新增商家 */
    addBusinessData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/shop/shop`,
            method: 'POST',
            data
        });
    },
    /* 更新商家信息 */
    upBusinessData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/shop/shop/${data.id}`,
            method: 'PUT',
            data
        });
    },
    /* 删除商家 */
    deleteBusinessData(data: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/shop/shop/${data.id}`,
            method: 'DELETE',

        });
    },
    /* 上架 */
    onlineBusinessData(data: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/shop/shop/online/${data.id}`,
            method: 'PUT',

        });
    },
    /* 下架 */
    offlineBusinessData(data: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/shop/shop/offline/${data.id}`,
            method: 'PUT',

        });
    }
}
export default BusinessAPI;