import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Upload,
  DatePicker,
  Switch,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import MyEditor from "@/components/MyEditor";
import { BASE_URL } from "@/api/config";
import CouponAPI from "@/api/modules/coupon";
import BusinessAPI from "@/api/modules/business";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import type { RangePickerProps } from "antd/es/date-picker";
const { RangePicker } = DatePicker;
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
  handleSuccess: any;
  applicableDict: any;
  couponsDict: any;
  lableDict: any;
};
const { Option } = Select;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    // console.log(file,'图片链接');
    
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Popup = (props: Props) => {
  let [html, setHtml] = useState<any>("");
  let [loading, setLoading] = useState<boolean>(false);
  /* 商家列表 */
  let [businessList, setBusinessList] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const addFrom: any = useRef();
  /* 下拉框的值 */

  const [couponsValue, setCouponsValue] = useState<any>([]);
  const [applicableValue, setApplicableValue] = useState<any>([]);
  const [businessValue, setBusinessValue] = useState<any>([]);
  const [limitedTime, setLimitedTime] = useState<number>(0);
  /*   const dateFormat = ["yyyy-MM-DD HH:mm:ss", "yyyy-MM-DD HH:mm:ss"]; */
  /*   const dateFormat = 'YYYY-MM-DD HH:mm:ss' */
  /* 已经选择的日期范围 */
  const [conponStart, setConponStart] = useState<any>(null);
  const [conponEnd, setConponEnd] = useState<any>(null);
  let [TimeValue, setTimeValue] = useState<any>([]);
  const [lableValue, setLableValue] = useState<any>([]);
  useEffect(() => {
    getBusinessList("");
  }, []);
  // useEffect(() => {
  //   if(props.record){

  //   }else{
  //     setHtml("");
  //   }
   
  // }, [html]);
  useEffect(() => {
 
    if (props.record.id) {
      // console.log('我啊',props.record);
      setHtml(props.record.conponInfo)
      if (props.record.pictureUrl) {
        let arr = props.record.pictureUrl.split(",");
        arr = arr.map((item: any, index: number) => {
          return {
            thumbUrl: item,
            name: index + "xxxx",
            response: {
              code: 200,
              data: item,
            },
          };
        });
        console.log(arr);

        setFileList(arr);
      }

      props.record.shopIds = props.record.shopVOS.map(
        (em: { shopName: any }) => {
          return em.shopName;
        }
      );
      /*       props.record.shopIds = props.record.shopVOS.map((em: { shopName: any }) => {
        return em.shopName;
      }); */
      setTimeout(()=>{
        addFrom.current.setFieldsValue({
          ...props.record,
          able: props.record.able ? props.record.able.split(",") : [],
        });
        },100);
      
      setBusinessValue([...props.record.shopIds]);
      setLimitedTime(props.record.limitedTime);
      if (props.record.conponStart) {
        setConponStart(props.record.conponStart);
        setConponEnd(props.record.conponEnd);

        let t = new Date(props.record.conponStart);
        let d = new Date(props.record.conponEnd);
        console.log(t, d);

        setTimeValue([{ _d: t }, { _d: d }]);
      }
    }else{
      setHtml('')
    }
  }, [props.record]);

  /* 选择券类型 */
  const handleCouponsChange = (value: any) => {
    console.log(value, "选择的数据");
    setCouponsValue(value);
  };
  /* 选择适用类型 */
  const handleApplicableChange = (value: any) => {
    console.log(value, "选择的数据");
    setApplicableValue(value);
  };
  /* 选择商家 */
  const handleBusinessChange = (value: any) => {
    console.log(value);

    setBusinessValue([...value]);
  };
  /* 选择标签
   */
  const handleLableChange = (value: any) => {
    console.log(value, "选择的数据");
    setLableValue(value);
  };

  /* 表单提交  同时获取逆地址编码*/
  const onFinish = async (values: any) => {
    // console.log('我是html',html);
    values.conponInfo=html;
    values.able = values.able.join(",");
   

    /*   console.log(values);
    console.log(fileList); */
    values.shopIds = businessList
      .filter((item: { shopName: string }) =>
        values.shopIds.includes(item.shopName)
      )
      .map((em: { id: string }) => {
        return em.id;
      });
    /*    console.log( values.shopIds); */
    let fist: {
      code: number | 0;
    } = { ...(fileList[0]?.response || "") };
    if (fist.code !== 200) {
      /*    console.log(fileList, "2"); */
      setFileList([]);
      message.error("请上传图片");
      return false;
    } else {
      console.log(fileList, "3");
      values.pictureUrl = fileList
        .map((item: { response: { data: any } }) => {
          return item.response.data;
        })
        .join(",");
    }
    if (TimeValue.length) {
      console.log(TimeValue);

      let arr = TimeValue.map((item: { _d: any }) => {
        let dateee = new Date(item._d).toJSON();
        return new Date(+new Date(dateee) + 8 * 3600 * 1000)
          .toISOString()
          .replace(/T/g, " ")
          .replace(/\.[\d]{3}Z/, "");
      });
      values.conponStart = arr[0];
      values.conponEnd = arr[1];
    } else {
      values.conponStart = "";
      values.conponEnd = "";
    }
    values.state = 0;

    values.limitedTime = limitedTime;
    let obj = {
      ...values,
    };
     setLoading(true);
    if (props.record.id) {
      const req: any = await CouponAPI.upCouponData({
        id: props.record.id,
        ...obj,
      });
      if (req.code === 200) {
     
        message.success("编辑成功");
        removeForm();
        props.handleSuccess();
      }
    } else {
      console.log("Success:", obj);
      const req: any = await CouponAPI.addCouponData(obj);
      if (req.code === 200) {
        message.success("添加成功");
        removeForm();
        props.handleSuccess();
       
      }
    }
    setLoading(false);
    // setHtml('')
  };

  /* 清空表单数据 */
  const removeForm = () => {
    setConponStart(null);
    setConponEnd(null);
    setFileList([]);
    setTimeValue([]);
    addFrom.current.resetFields();
    // setHtml('')
  };
  /* 表单关闭 */
  const handleCancel = () => {
   
    removeForm();
    props.handleCancel();

    console.log('我是html',html);
    
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  /* 图片上传 */
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  /* 图片上传 */
  const handleCancelImg = () => setPreviewVisible(false);
  /* 图片上传 */
  const handlePreview = async (file: UploadFile) => {
    console.log(file, "图片链接啊啊啊啊");
    if (!file.url && !file.preview) {
      if(props.record){
        file.preview =file.thumbUrl;
      }else{
file.preview = await getBase64(file.originFileObj as RcFile);
      }
      
    }
    
    

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  /* 图片上传 */
  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
    event,
  }) => {
    console.log(file, "file");
    console.log(event, "event");
    console.log(newFileList, "fileList");
    setFileList(newFileList);
    /*     if (!file.response){
      message.error("文件过大,请重新上传");
    }
 */
  };
  /* 获取商家 */
  const getBusinessList = async (shopName: string) => {
    const res: any = await BusinessAPI.getBusinessList({
      size: 999,
      current: 1,
      contactPhone: "",
      shopCadre: "",
      shopName,
    });

    if (res.code === 200) {
      let arr: any = JSON.stringify(res.data);
      let obj: any = JSON.parse(arr) || {};
      console.log(obj.records);

      setBusinessList([...obj.records]);
    }
  };
  /*  */
  const onSearch = (e: any) => {
    console.log(e, "文本框变化");
    getBusinessList(e);
  };

  /* 选择时间 */
  const onchangeTime = (e: any) => {
    console.log(e);
    if (e) {
      setTimeValue(e);
      setConponStart(e[0]);
      setConponEnd(e[1]);
    } else {
      setConponStart(null);
      setConponEnd(null);
    }
  };
  /* 设置不可选择时间 */
  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    return current < moment().subtract(1, "day");
  };
  /* 是否限定时段使用 */

  const switchChange = (e: any) => {
    if (e) {
      setLimitedTime(1);
    } else {
      setLimitedTime(0);
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  return (
    <Modal
      title={props.record ? "编辑优惠券" : "新增优惠券"}
      maskClosable={false}
      width={1100}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      onCancel={handleCancel}
      okText="确认"
      cancelText="取消"
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        <div className="addFrom_box">
          <div className="form_box">
            <div>
              <Form.Item
                label="餐券名称"
                name="conponName"
                rules={[{ required: true, message: "请输入餐券名称" }]}
              >
                <Input placeholder="请输入餐券名称" />
              </Form.Item>

              <Form.Item
                label="券类型"
                name="conponType"
                rules={[{ required: true, message: "请输入餐券名称" }]}
              >
                {
                  <Select
                    onChange={handleCouponsChange}
                    value={couponsValue}
                    placeholder="请选择券类型"
                  >
                    {props.couponsDict.map((item: any) => (
                      <Option key={item.code}>{item.name}</Option>
                    ))}
                  </Select>
                }
              </Form.Item>
              <Form.Item
                label="适用类型"
                name="conponUse"
                rules={[{ required: true, message: "请输入餐券名称" }]}
              >
                <Select
                  onChange={handleApplicableChange}
                  value={applicableValue}
                  placeholder="请选择适用类型"
                >
                  {props.applicableDict.map((item: any) => (
                    <Option key={item.code}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="销售价格"
                name="marketingPrice"
                rules={[{ required: true, message: "请输入价格" }]}
              >
                <Input placeholder="请销售价格" />
              </Form.Item>
              <Form.Item
                label="结算价"
                name="settlePrice"
                rules={[{ required: true, message: "请输入价格" }]}
              >
                <Input placeholder="请输入价格" />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                label="适用商家"
                name="shopIds"
                rules={[{ required: true, message: "请选择适用商家" }]}
              >
                <Select
                  autoClearSearchValue={false}
                  mode="multiple"
                  onChange={handleBusinessChange}
                  placeholder="请选择适用商家"
                  onSearch={onSearch}
                  value={businessValue}
                  key={"multiple"}
                >
                  {businessList.map((item: any) => (
                    <Option
                      key={item.id}
                      value={item.shopName}
                      label={item.shopName}
                    >
                      {item.shopName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="数量"
                name="saleAmount"
                rules={[{ required: true, message: "请填写数量" }]}
              >
                <Input placeholder="请填写数量" />
              </Form.Item>

              <Form.Item
                label="单人限购"
                name="quota"
                rules={[{ required: true, message: "请输入单人限购数量" }]}
              >
                <Input placeholder="请输入单人限购数量" />
              </Form.Item>
              <Form.Item label="用券时间">
                <Switch
                 key={props.record.id||'ddddd'}
                  onChange={switchChange}
                  checkedChildren="限制"
                  unCheckedChildren="不限制"
                  defaultChecked={props.record.limitedTime === 1}
                ></Switch>
              </Form.Item>
              {limitedTime === 1 ? (
                <Form.Item
                  label="使用时间"
                  /*   rules={[{ required: true, message: "请选择使用时间" }]} */
                  /*   name="conponStart" */
                >
                  <>
                    {
                      <RangePicker
                        locale={locale}
                        /*      disabledDate={disabledDate} */
                        onChange={onchangeTime}
                        showTime
                        /*  format={dateFormat} */
                        /*      defaultValue={[
                        moment(conponStart),
                        moment(conponEnd),
                      ]} */
                        value={
                          conponStart && conponEnd
                            ? [
                                moment(conponStart, "YYYY-MM-DD hh:mm:ss"),
                                moment(conponEnd, "YYYY-MM-DD hh:mm:ss"),
                              ]
                            : null
                        }
                        placeholder={["开始时间", "结束时间"]}
                      />
                    }
                  </>
                </Form.Item>
              ) : null}
              <Form.Item
                label="原价"
                name="costPrice"
                rules={[{ required: true, message: "请输入原价" }]}
              >
                <Input placeholder="请输入原价" />
              </Form.Item>

              <Form.Item
                label="标签"
                name="able"
                rules={[{ required: true, message: "请选择标签" }]}
              >
                <Select
                  mode="multiple"
                  onChange={handleLableChange}
                  value={lableValue}
                  placeholder="请选择标签"
                >
                  {props.lableDict.map((item: any) => (
                    <Option key={item.name}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="form_box">
            <Form.Item
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
              label="餐券介绍"
              name="conponInfo"
              // rules={[{ required: true, message: "请输入餐券介绍" }]}
            > 
              <MyEditor html={html} setHtml={setHtml} />
            </Form.Item>
          </div>
          <div className="form_box">
            <Form.Item
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
              label="使用说明"
              name="instructions"
              rules={[{ required: true, message: "请输入使用说明" }]}
            >
              <Input.TextArea
                style={{ height: 120 }}
                maxLength={250}
                placeholder="请输入使用说明"
              />
            </Form.Item>
          </div>
          <Form.Item
            label="餐券图片"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
          >
            <Upload
              action={
                BASE_URL +
                "/api/open/aliOssFile/upload?filePath=image/&resType=1"
              }
              accept="image/png, image/jpeg"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              headers={{
                TOKEN: sessionStorage.getItem("token") || "",
              }}
              name="file"
              onChange={handleChange}
            >
              {fileList.length >= 9 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancelImg}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </Form.Item>
          <div className="tab_but">
            <Button size="middle" onClick={handleCancel}>
              取消
            </Button>
            <Button
              type="primary"
              size="middle"
              loading={loading}
              htmlType="submit"
            >
              确定
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default Popup;
