import GOODSAPI from "@/api/modules/goods";
import SourceExchangeAPI from "@/api/modules/sourceExchange";

import { DataList } from "@/utils/tsModules/goods";
import { Button, Input, Select, Space, Modal, message } from "antd";
import MyEditor from "@/components/MyEditor";
import { Key, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableList from "./components/Table";
import Popup from "./components/popup";
import "./index.less";
const GoodsList = () => {
  const handleCancel = () => {
    setRecord("");
    setIsModalVisible2(false);
  };
  // 兑换码状态
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setID] = useState<any>('');
  const [record, setRecord] = useState<any>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { Option } = Select;
  const [isModalVisible2, setIsModalVisible2] = useState<boolean>(false);
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const conponNameRef = useRef<any>();
  const [conponState, setConponState] = useState<any>("");
  const navigate = useNavigate();
  const [html, setHtml] = useState("");
  const [page, setListPage] = useState<DataList>({
    current: 1,
    size: 10,
    user: "",
    type: ""
  });
  const handleOk2 = () => {
    setIsModalOpen(false);
    setHtml('')
  };

  const handleCancel2 = () => {
    setIsModalOpen(false);
  };
  const showModal2 = () => {
    setIsModalOpen(true);

  };
  const ruleRadio=async ()=>{
    const req: any = await SourceExchangeAPI.getSourceRule({});
    setIsModalOpen(true)
    setID(req.data.records[0].id)
    setHtml(req.data.records[0].exchangeRules)
    // console.log(req.data.records.exchangeRules);
    
  }
  const exchange = async () => {

    if (id) {
      // console.log('规则',html.length);
      // console.log('规则',html);
      if (html.length!==11) {
        const res: any = await SourceExchangeAPI.updateSourceRule({ exchangeRules: html, forceProId: id });
        if (res.data) {
          message.success(res.msg);
          setIsModalOpen(false);
         
        }
      } else {
        message.warning('请输入活动规则');
      }

    }


    //  if(req.code===200){
    //   handleCancel2();
    //   message.success("发货成功");
    //   props.getListData();
    //  }
  };
  useEffect(() => {
    getListData();
  }, [page]);
  const getListData = async () => {
    setLoading(true);
    const res: any = await SourceExchangeAPI.getSourceList(page);
    console.log(res, "源力值商品兑换");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  // const showModal = (record: Object) => {
  //   navigate("/home/goodsEdit");
  // };
  const showModal = (record: Object) => {
    // navigate("/home/banner/edit");
    // 弹出新增窗口
    setRecord(record);
    setIsModalVisible2(true);
  };

  const handleSuccess = () => {
    setIsModalVisible(false);
    getListData();
  };

  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      user: conponNameRef.current.input.value,
      type: conponState === 0 || conponState ? conponState : "",
    };
    console.log('我阿阿阿阿', obj);

    setListPage({ ...obj });
  };
  const [seletData, setSeletData] = useState<any>([
    { state: 0, name: "自提" },
    { state: 1, name: "邮寄" },
  ]);
  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };

  const options = seletData.map(
    (item: { state: Key | null | undefined; name: string }) => (
      <Option key={item.state} value={item.state}>
        {item.name}
      </Option>
    )
  );
  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="商品名称" ref={conponNameRef} allowClear />
        <Select placeholder="状态" onChange={changeConponState} allowClear className="state-sel">
          {options}
        </Select>
        <Button onClick={searchInPut} size="middle">
          查询
        </Button>
        <Button
          type="primary"
          onClick={() => {
            showModal("");
          }}
          size="middle"
        >
          兑换
        </Button>
        <Button onClick={ ruleRadio} className='rule-btn' size="middle" type="primary">
          编辑活动规则
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
          getListData={getListData}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible2}
        record={record}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
        listData={listData}
        getListData={getListData}
      ></Popup>


      <Modal title="活动规则" footer={null} visible={isModalOpen} onOk={handleOk2} onCancel={handleCancel2}>
        <MyEditor html={html} setHtml={setHtml} />
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '350px', marginLeft: "60px", marginTop: "30px" }}>
          <Button onClick={() => { handleOk2() }} style={{ width: '150px' }} type="primary">取消</Button>

          <Button onClick={() => { exchange() }} style={{ width: '150px' }}>确认修改</Button></div>


      </Modal>
    </div>
  );
};

export default GoodsList;
