import {
  Button,
  Form,
  Input,
  Modal,
  Image,
  message,
  Card,
  Table,
  Space,
} from "antd";
import { useRef, useState, useEffect } from "react";
import userAPI from "@/api/modules/user";
import "../index.less";
import "moment/locale/zh-cn";
import GoodsOrderApi from "@/api/modules/goodsOrder";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  handleSuccess: any;
};

const Popup = (props: Props) => {
  const { record } = props;
  let [loading, setLoading] = useState<boolean>(false);
  let [stuRemarks, setStuRemarks] = useState<string>("");
  const addFrom: any = useRef();
  useEffect(() => {
    setStuRemarks(props.record.stuRemarks);
  }, []);
  /* 审核通过*/
  const onFinish = async () => {
    setLoading(true);
    const res: any = await userAPI.putExamineAdopt({
      id: props.record.id,
      stuRemarks: stuRemarks,
    });
    if (res.code === 200) {
      message.success("审核完成");
      removeForm();
      props.handleSuccess();
    }
    setLoading(false);
  };

  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const handleErrar = async () => {
    if (!stuRemarks) {
      return message.error("请填写未通过原因");
    }
    setLoading(true);
    const res: any = await userAPI.putExamineFailed({
      id: props.record.id,
      stuRemarks: stuRemarks,
    });
    if (res.code === 200) {
      message.success("审核完成");
      setLoading(false);
      removeForm();
      props.handleSuccess();
    }
  };
  /* 获取备注数据 */
  const inputTextArea = (e: any) => {
    if (e.target.value.length <= 20) {
      setStuRemarks(e.target.value);
    }
  };
  /* 发货 */
  const putCommodityOrderSub = async (value: any) => {
    console.log(value);
    if (value) {
      const res: any = await GoodsOrderApi.putCommodityOrderSub({
        ...record,
        ...value,
        state:3
      });
      if (res.code === 200) {
        message.success(res.msg);
        props.handleSuccess();
      }
    } else {
      message.error("请输入物流单号");
    }
  };
  const columns: any = [
    {
      title: "",
      dataIndex: "num",
      key: "id",
      render: (_: any, record: any) => (
        <div>
          <img src={record.proPicture} alt="" width={100} height={100} />
        </div>
      ),
    },
    { title: "商品", dataIndex: "commodityName", key: "id" },
    { title: "规格", dataIndex: "commoditySpecification", key: "id" },

    {
      title: "数量",
      dataIndex: "num",
      key: "id",
      render: (_: any, record: any) => <span>x{record.num}</span>,
    },
    { title: "实付金额", dataIndex: "price", key: "id" },
  ];
  return (
    <Modal
      title={record.look ? "订单详情" : "发货"}
      maskClosable={false}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      width={800}
      onCancel={handleCancel}
    >
      <Form
        ref={addFrom}
        onFinish={putCommodityOrderSub}
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        size="large"
      >
        <Form.Item
          name="nogisticsNo"
          rules={[{ required: true, message: "请输入物流单号" }]}
        >
          <Card style={{ width: "100%" }} hoverable>
            <p className="card_p_order">
              <span>
                {record.state === 1
                  ? `下单时间：${record.createTime}`
                  : record.state === 2
                  ? `支付时间：${record.payTime}`
                  : record.state === 6
                  ? `取消时间：${record.cancelTime}`
                  : record.state === 7
                  ? `取消时间：${record.cancelTime}`
                  : ""}
              </span>
              <span>下单人：{record.userName}</span>
            </p>
            <p className="card_p_order">
              <span>订单编号：{record.commodityOrderNo}</span>
              <span>联系电话：{record.userPhone}</span>
            </p>
            {record.look ? (
              <p className="card_p_order">物流单号：{record.nogisticsNo}</p>
            ) : (
              <div>
                物流单号：
                <Input placeholder="请填写物流单号" />
              </div>
            )}
          </Card>
        </Form.Item>
        <Form.Item>
          <Card style={{ width: "100%" }} hoverable>
            <Table
              tableLayout="auto"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={[props.record]}
              pagination={false}
            />
          </Card>
        </Form.Item>
        <Form.Item>
          <Card style={{ width: "100%" }} hoverable>
            <Space size={40}>
              <span>{record.userName}</span> <span>{record.userPhone}</span>
              <span>{record.userAddress}</span>
            </Space>

            <p className="card_p_order">
              <span>买家留言：{record.buyerMessage}</span>
            </p>
          </Card>
        </Form.Item>
        {record.look ? null : (
          <Form.Item>
            <div className="from_fh_but">
              <Button
                type="primary"
                size="middle"
                loading={loading}
                htmlType="submit"
              >
                确认发货
              </Button>
            </div>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default Popup;
