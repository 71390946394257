import { service, BASE_URL } from "../request";
const ClassApi = {
    /* 获取分类列表 */
    getClassList(params: { current: number, size: number }) {
        return service({
            url: `${BASE_URL}/api/admin/product/sort/list`,
            params
        });
    },
    /* 分类上架 */
    putClassOnline(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/sort/online/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 分类下架 */
    putClassOffline(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/sort/offline/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 推荐分类 */
    putClassOnReferee(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/sort/onReferee/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 不推荐分类 */
    putClassOffReferee(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/sort/offReferee/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 删除分类 */
    deleteClassById(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/sort/${params.id}`,
            params,
            method: 'DELETE'
        });
    },
    /* 添加分类 */
    addClassData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/product/sort`,
            data,
            method: 'POST'
        });
    },
    /* 编辑分类 */
    updeatClassById(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/product/sort/${data.id}`,
            data,
            method: 'PUT'
        });
    },
}
export default ClassApi;