
import { useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { getLogin } from "../../../api/modules/login";

import { UserOutlined, LockOutlined, CloseCircleOutlined, SafetyCertificateOutlined } from "@ant-design/icons";

type From = {
	username: string,
	password: string,
	code?: string
	terminal?: number
}

const LoginForm = (props: any) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const [code, setCode] = useState<string>();

	useEffect(() => {
		getCodeData()
	}, [])
	// 登录
	const onFinish = async (loginForm: From) => {
		try {
			if (code !== loginForm.code) {
				 message.error('验证码错误');
			setTimeout(()=>{
				getCodeData()
			},500)
				 return false
			}
			setLoading(true);
			delete loginForm.code
			loginForm.terminal = 1
			const res: any = await getLogin(loginForm);
			if (res.code === 200) {
				message.success("登录成功！")
				navigate('/home')
			} else {
				setTimeout(()=>{
					getCodeData()
				},500)
			}
			/* 	setToken(data?.access_token);
				setTabsList([]);
			; */

		} finally {
			setLoading(false);
		}
	};

	const onFinishFailed = (errorInfo: any) => {

	};
	const getCodeData = () => {
		let arr = `${Math.ceil(Math.random() * 9)}${Math.ceil(Math.random() * 9)}${Math.ceil(Math.random() * 9)}${Math.ceil(Math.random() * 9)}`;
		setCode(arr)
	};
	return (
		<Form
			form={form}
			name="loginForm"
			labelCol={{ span: 5 }}
			initialValues={{ remember: true }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			size="large"
		 autoComplete="off"
		>
			<Form.Item name="username" rules={[{ required: true, message: "请输入用户名" }]}>
				<Input placeholder="请输入用户名" allowClear prefix={<UserOutlined />} />
			</Form.Item>
			<Form.Item name="password" rules={[{ required: true, message: "请输入密码" }]}>
				<Input.Password autoComplete="new-password" placeholder="请输入密码" prefix={<LockOutlined />} />
			</Form.Item>
			<Form.Item  >
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item
							name="code"
							noStyle
							rules={[{ required: true, message: '请输入验证码' }]}

						>
							<Input allowClear placeholder="请输入验证码" prefix={<SafetyCertificateOutlined />} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<div className="code pointer_cursor none_selet" onClick={() => { getCodeData() }}>
							{code}
						</div>
					</Col>
				</Row>
			</Form.Item>

			<Form.Item className="login-btn">
				<Button
					onClick={() => {
						form.resetFields();
					}}
					icon={<CloseCircleOutlined />}
				>
					{("重置")}
				</Button>
				<Button type="primary"
					htmlType="submit"

					loading={loading}
					icon={<UserOutlined />}>
					{("登录")}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default LoginForm;
