import SystemApi from "@/api/modules/system";
import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  Table,
  TableColumnsType,
} from "antd";
import { Key, useEffect, useState } from "react";
import "./table.less";
interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  showModal: Function;
  setListData: Function;
  listData: any;
};
const TableList = (props: Props) => {
  const [listData, setListData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!props.isModalVisible) {
      getDictList();
    }
  }, [props.isModalVisible]);

  const getDictList = async () => {
    const res: any = await SystemApi.getMenuList({ isTree: true });
    console.log(res, "菜单列表");

    if (res.code === 200) {
      setListData([...res.data]);
      if (loading) {
        setLoading(false);
      }

      props.setListData([{ name: "顶级", id: "0", menus: [...res.data] }]);
    }
  };

  const onSwitchChange = async (checked: number, event: any) => {
    const res: any = await SystemApi.updateStateMenuData({
      state: checked ? 0 : 1,
      id: event.id,
    });
    if (res.code === 200) {
      getDictList();
      message.success("编辑成功");
    }
  };
  const deleteDictById = async (data: { id: string }) => {
    const res: any = await SystemApi.deleteMenuData(data);
    if (res.code === 200) {
      getDictList();
      message.success("删除成功");
    }
  };

  const cancel = (e: any) => {
    message.error("取消");
  };
  const columns: TableColumnsType<DataType> = [
    { title: "菜单排序", dataIndex: "sort", key: "id" },
    { title: "创建时间", dataIndex: "createTime", key: "id" },
    { title: "菜单名称", dataIndex: "name", key: "id" },
    { title: "终端 ", dataIndex: "terminal", key: "id" },
    {
      title: "图标 ",
      dataIndex: "icon",
      key: "id",
    },
    { title: "url", dataIndex: "url", key: "id" },
    {
      title: "状态",
      dataIndex: "disable",
      key: "id",
      render: (_: any, record: any) => (
        <div>
          {/*  { record.disable} */}
          {
            <Switch
              checkedChildren="启用"
              unCheckedChildren="禁用"
              defaultChecked={record.disable === 0}
              key={record.id}
              onChange={() => {
                onSwitchChange(record.disable, record);
              }}
            />
          }
        </div>
      ),
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              props.showModal(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定删除菜单吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={loading} size="large">
        <Table
          scroll={{ y: "65vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "menus" }}
          dataSource={listData}
          pagination={false}
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
