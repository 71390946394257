import { Button, Form, Input, Modal, Image, message } from "antd";
import { useRef, useState, useEffect } from "react";
import userAPI from "@/api/modules/user";
import "moment/locale/zh-cn";
import SystemApi from "@/api/modules/system";
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  handleSuccess: any;
};

const Popup = (props: Props) => {
  let [loading, setLoading] = useState<boolean>(false);
  let [stuRemarks, setStuRemarks] = useState<string>("");
  const addFrom: any = useRef();
  useEffect(() => {
    if (props.record.id) {
      // addFrom.current.setFieldsValue({
      //   ...props.record,
      // });
      setTimeout(() => {
        addFrom.current.setFieldsValue({
          ...props.record,
        });
      }, 0);
      console.log(props.record);
    }
  }, [props.record.id]);
  /* 审核通过*/
  const onFinish = async (value: any) => {
    let obj = {};
    if (props.record.id) {
      obj = {
        ...props.record,
        ...value,
      };
      const res: any = await SystemApi.putRoleByIdData(obj);
      if (res.code === 200) {
    
        message.success("成功");
        removeForm();
        props.handleSuccess();
      }
    } else {
      obj = {
        ...value,
        menuIds: [],
        disable: 0,
        terminal: 1,
      };
      const res: any = await SystemApi.addRoleByIdData(obj);
      if (res.code === 200) {
        message.success("新增成功");
        removeForm();
        props.handleSuccess();
      }
    }
    setLoading(false);
  };

  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const handleErrar = async () => {
    if (!stuRemarks) {
      return message.error("请填写未通过原因");
    }
    setLoading(true);
    const res: any = await userAPI.putExamineFailed({
      id: props.record.id,
      stuRemarks: stuRemarks,
    });
    if (res.code === 200) {
      message.success("审核完成");
      setLoading(false);
      removeForm();
      props.handleSuccess();
    }
  };
  return (
    <Modal
      title={props.record.id ? "编辑" : "新增"}
      maskClosable={false}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      onCancel={handleCancel}
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        size="large"
        onFinish={onFinish}
      >
        <Form.Item
          label="角色CODE"
          name="code"
          rules={[{ required: true, message: "角色CODE" }]}
        >
          <Input placeholder="请输入角色CODE" />
        </Form.Item>
        <Form.Item
          label="角色名称"
          name="name"
          rules={[{ required: true, message: "角色名称" }]}
        >
          <Input placeholder="请输入角色名称" />
        </Form.Item>
        <Form.Item
          label="角色描述"
          name="desc"
          rules={[{ required: true, message: "角色描述" }]}
        >
          <Input placeholder="请输入角色描述" />
        </Form.Item>

        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Popup;
