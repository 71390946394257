import TableList from "./components/Table";
import userAPI from "@/api/modules/wxuser";
import { Button, Input, Select, Space } from "antd";
import { Key, useEffect, useRef, useState } from "react";
import "./index.less";
type Props = {};
const WxUser = (props: Props) => {
  const { Option } = Select;
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [conponState, setConponState] = useState<any>("");
  const userNameRef = useRef<any>();
  const [page, setListPage] = useState<any>({
    current: 1,
    size: 10,
    keyword: "",
  });
  /* 存储 券类型*/
  useEffect(() => {
    getListData();
  }, [page]);
  /* 获取字典 */
  const getListData = async () => {
    setLoading(true);
    const res: any = await userAPI.getUserList(page);
    console.log(res, "用户的列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      terminal: 2,
      keyword: userNameRef.current.input.value,
      position:conponState===0||conponState===1?conponState:''
    };
    console.log(obj);

    setListPage({ ...obj });
  };

  const [seletData, setSeletData] = useState<any>([
    { state: 0, name: "普通用户" },
    { state: 1, name: "网格员" },
  ]);
  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };

  const options = seletData.map(
    (item: { state: Key | null | undefined; name: string }) => (
      // eslint-disable-next-line react/jsx-no-undef
      <Option key={item.state} value={item.state}>
        {item.name}
      </Option>
    )
  );

  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="姓名或者电话" ref={userNameRef} allowClear />
        <Select placeholder="用户类型" onChange={changeConponState} allowClear className="state-sle">
          {options}
        </Select>
        <Button type="primary" onClick={searchInPut} size="middle">
          查询
        </Button>
      </Space>
      {
        <TableList
          setListData={setListData}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
          getListData={getListData}
        ></TableList>
      }
    </div>
  );
};

export default WxUser;
