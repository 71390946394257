import TableList from "./components/Table";
import Popup from "./components/popup";
import { Button, Space } from "antd";
import { useState } from "react";
type Props = {};

const MenuAdmin = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>("");
  const [listData, setListData] = useState<any>("");
  const showModal = (record: Object) => {
    setRecord(record);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <Space size={8}>
        {
          <Button
            type="primary"
            onClick={() => {
              showModal("");
            }}
            size="middle"
          >
            新增
          </Button>
        }
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          showModal={showModal}
          setListData={setListData}
          listData={listData}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible}
        record={record}
        handleCancel={handleCancel}
        listData={listData}
      ></Popup>
    </div>
  );
};

export default MenuAdmin;
