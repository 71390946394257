import BrandApi from "@/api/modules/brand";
import { Button, Form, Input, message, Modal, Select } from "antd";
import { useEffect, useRef, useState } from "react";
const { Option } = Select;
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any | "";
  handleSuccess: any;
  setSublistData: Function;
  sublistData: any;
};

const Popup = (props: Props) => {
  let [loading, setLoading] = useState<boolean>(false);
  const [brandValue, setBrandValue] = useState<string>();
  const addFrom: any = useRef();
  useEffect(() => {
    if (props.record) {
      setTimeout(() => {
        addFrom.current.setFieldsValue({
          ...props.record,
        });
      }, 0);
      
    }
  }, [props.record]);

  /* 表单提交 */
  const onFinish = async (values: any) => {
    console.log(props.record);
    console.log(values);
    values.state = 0;
    values.parcelState -= 0;
    if (props.record) {
      props.sublistData.splice(props.record.index, 1, values);
      props.setSublistData([...props.sublistData]);
    } else {
      const fala: boolean = props.sublistData.some(
        (item: { normsName: any }) => {
          return item.normsName === values.normsName;
        }
      );
      if (fala) {
        return message.error("规格已存在");
      }

      props.setSublistData([...props.sublistData, values]);
    }
    removeForm();
    props.handleCancel();
  };

  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  /* 存储是否包邮 */
  const handleChangeSeletBrand = (newValue: string) => {
    console.log(newValue);

    setBrandValue(newValue);
  };
  const Specifications = [
    {
      name: "是",
      id: "0",
    },
    { name: "否", id: "1" },
  ];
  const optionsC = Specifications.map(
    (item: { id: React.Key | null | undefined; name: string }) => (
      <Option key={item.id}>{item.name}</Option>
    )
  );
  return (
    <Modal
      title={props.record ? "编辑规格" : "新增规格"}
      maskClosable={false}
      width={600}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      onCancel={handleCancel}
      footer={false}
      okText="确认"
      cancelText="取消"
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        <Form.Item
          label="规格名称"
          name="normsName"
          rules={[{ required: true, message: "请输入规格名称" }]}
        >
          <Input placeholder="请输入规格名称" />
        </Form.Item>
        <Form.Item
          label="售价"
          name="sellingPrice"
          rules={[{ required: true, message: "请输入售价" }]}
        >
          <Input type="number" placeholder="请输入售价" />
        </Form.Item>
        <Form.Item
          label="原价"
          name="costPrice"
          rules={[{ required: true, message: "请输入原价" }]}
        >
          <Input type="number" placeholder="请输入原价" />
        </Form.Item>
        <Form.Item
          label="是否包邮"
          name="parcelState"
          rules={[{ required: true, message: "请选择" }]}
        >
          <Select
            value={brandValue}
            placeholder="请选择"
            defaultActiveFirstOption={true}
            showArrow={false}
            filterOption={false}
            onChange={handleChangeSeletBrand}
            notFoundContent={null}
          >
            {optionsC}
          </Select>
        </Form.Item>
        {brandValue === "1" ? (
          <Form.Item
            label="邮费"
            name="postage"
            rules={[{ required: true, message: "请输入邮费" }]}
          >
            <Input type="number" placeholder="请输入邮费" />
          </Form.Item>
        ) : null}
        <Form.Item
          label="库存"
          name="stock"
          rules={[{ required: true, message: "请输入库存" }]}
        >
          <Input type="number" placeholder="请输入库存" />
        </Form.Item>

        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Popup;
