import { BASE_URL } from "@/api/config";
import CouponAPI from "@/api/modules/coupon";
import BannerAPi from "@/api/modules/banner";
import { PlusOutlined } from "@ant-design/icons";
import { message, Modal, RadioChangeEvent } from "antd";
import { Button, Input, Radio, Select, Space, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import React, { Key, useEffect, useRef, useState } from "react";

import "./banneredit.less";
import MyEditor from "@/components/MyEditor";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
type Props = {};
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function BannerEdit({}: Props) {
  const [listData, setListData] = useState<any>([]);
  const [bannerAddressData, setBannerAddressData] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [conponState, setConponState] = useState<any>("");
  /* 图片上传 */
  const [html, setHtml] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  /* 是否有连接 */
  const [urlfala, setUrlFale] = useState<number>(0);
  /* 图文还是id */
  const [isSkip, setIsSkip] = useState<number>(0);
  /* 获取名称 排序 */
  const nameRef = useRef<any>();
  const sortRef = useRef<any>();
  const [bannername, setBannername] = useState<any>("");
  const [solt, setSolt] = useState<any>("");
  const [id, setId] = useState<any>(null);
  useEffect(() => {
    /* 获取优惠券列表 */

    let id = window.location.hash.split("?id=")[1];
    setId(id);
    console.log(id);
     
    if (id) {
      getBannerByIdData(id);
    }

    getListData("");
  }, []);
  /* 图片上传 */
  const handleCancelImg = () => setPreviewVisible(false);
  /* 图片上传 */
  const navigate = useNavigate();
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      if(id){
        file.preview =file.thumbUrl;
      }else{
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  /* 图片上传 */
  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
    event,
  }) => {
    console.log(file, "file");
    console.log(event, "event");
    console.log(newFileList, "fileList");
    setFileList(newFileList);
    /*     if (!file.response){
      message.error("文件过大,请重新上传");
    }
 */
  };
  const changeConponState = (e: any) => {
    setConponState(e);
    console.log('banner位置',e);
    
  };

  /* 获取优惠券列表 */
  const getListData = async (conponName: any | "") => {
    const res: any = await CouponAPI.getCouponList({
      current: 1,
      size: 10,
      conponName,
      conponType: "",
      conponUse: "",
      state: "",
    });
    const re: any = await BannerAPi.findByCode({code:'BANNER_POSITION'});
    // console.log(' 哇 啊',re);
    
      if(re.code===200){
        setBannerAddressData([...re.data]);
      }
    if (res.code === 200) {
      setListData([...res.data.records]);
    }
  };
  /* 根据id 查询轮播图详情 */
  const getBannerByIdData = async (id: string) => {
    const res: any = await BannerAPi.getBannerDataById({ id });
      
    console.log(res, "字典查询");
    if (res.code === 200) {
      setUrlFale(res.data.isSkip ? 1 : 0);

      setIsSkip(res.data.isSkip);
      setBannername(res.data.name);
      setConponState(String(res.data.position));
  //  console.log(conponState);
   
      let fileList = [
        {
          thumbUrl: res.data.imgUrl,
          name: "xxxx",
          response: {
            code: 200,
            data: res.data.imgUrl,
          },
        },
      ];
      setFileList(fileList);
      setSolt(res.data.sort);
      if (res.data.isSkip === 1) {
        setValue(res.data.skipUrl);
      } else if (res.data.isSkip === 2) {
        setHtml(res.data.desc);
      }
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  /* 是否有连接 */
  const onChangeA = (e: RadioChangeEvent) => {
    if (!e.target.value) {
      setIsSkip(0);
    } else {
      setIsSkip(1);
    }
    setUrlFale(e.target.value);
  };
  /* 图文还是连接 */
  const onChangeB = (e: RadioChangeEvent) => {
    setIsSkip(e.target.value);
    if (e.target.value === 1) {
      setValue("");
    } else if (e.target.value === 2) {
      setHtml("");
    }
  };
  const [value, setValue] = useState<string>();

  const handleSearch = (newValue: string) => {
    console.log(newValue);

    if (newValue) {
      getListData(newValue);
    } else {
      getListData("");
    }
  };
  const handleChangeSelet = (newValue: string) => {
    setValue(newValue);
  };
  // eslint-disable-next-line react/jsx-no-undef
  const options = listData.map(
    (item: { id: React.Key | null | undefined; conponName: string }) => (
      <Option key={item.id}>{item.conponName}</Option>
    )
  );
  const option = bannerAddressData.map(
    (item: { id:string; name: string,code:string }) => (
      // eslint-disable-next-line react/jsx-no-undef
      <Option key={item.id} value={item.code}>
        {item.name}
      </Option>
    )
  );
  /* 发起提交 */
  const addBannerData = async () => {
    console.log(fileList);
    let imgUrl = fileList.length
      ? fileList[0].response?.code === 200
        ? fileList[0].response.data
        : ""
      : "";
    if (!imgUrl) {
      message.error("请上传合格的图片");
      setFileList([]);
      return false;
    }
    let name = nameRef.current.input.value;
    let sort = sortRef.current.input.value;

    if (!name) {
      message.error("请输入轮播图名字");
      return false;
    }
    if(conponState===undefined){
      message.error("请选择banner位置");
      return false;
    }
    if (isSkip === 2 && !html) {
      message.error("请输入文本");
      return false;
    }
    if (isSkip === 1 && !value) {
      message.error("请选择券");
      return false;
    }
    console.log(imgUrl);

    let obj: any = {
      name,
      position: conponState,
      desc: isSkip === 2 ? html : "", //描述富文本
      imgUrl, //图片地址
      sort,
      disable: 0, //富文本
      isSkip: isSkip, //是否跳转
      skipUrl: isSkip === 1 ? value : "", //券id
    };

    if (id) {
      const res: any = await BannerAPi.editBannerDataById({ id, ...obj });
      console.log(res, "添加的回调");
      if (res.code === 200) {
        message.success(res.msg);
        navigate("/home/banner");
      }
    } else {
      const res: any = await BannerAPi.addBannerData(obj);
      console.log(res, "添加的回调");
      if (res.code === 200) {
        message.success(res.msg);
        navigate("/home/banner");
      }
    }
  };
  /* 输入名字存储 */
  const bannernameChage = (e: any) => {
    setBannername(e.target.value);
  };
  const sortChange = (e: any) => {
    setSolt(e.target.value);
  };
  return (
    <div className="edit_box">
      <div className="foot">
        <Space size={20}>
          <Button type="primary" onClick={addBannerData}>
            确认
          </Button>
          <Button
            onClick={() => {
              navigate("/home/banner");
            }}
          >
            取消
          </Button>
        </Space>
      </div>
      <div className="min">
        <div className="zw_box"></div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> banner名称
          </div>
          <Input
            placeholder="请输入banner名称"
            ref={nameRef}
            value={bannername}
            onInput={bannernameChage}
            size="large"
          />
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> banner位置
          </div>
          <Select placeholder="请选择banner位置" onChange={changeConponState} allowClear value={conponState} className="state-sle">
          {option}
        </Select>
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> banner排序
          </div>
          <Input
            placeholder="请输入banner排序"
            ref={sortRef}
            onInput={sortChange}
            value={solt}
            type={"number"}
            size="large"
          />
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> 图片
          </div>
          <Upload
            action={
              BASE_URL + "/api/open/aliOssFile/upload?filePath=image/&resType=1"
            }
            accept="image/png, image/jpeg"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            headers={{
              TOKEN: sessionStorage.getItem("token") || "",
            }}
            name="file"
            onChange={handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancelImg}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={fileList[0]?.thumbUrl?fileList[0].thumbUrl:previewImage}
                />
              </Modal>
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> 是否有链接
          </div>
          <Radio.Group onChange={onChangeA} value={urlfala} className="radio">
            <Space size={50}>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Space>
          </Radio.Group>
        </div>
        {urlfala === 1 ? (
          <div className="flex">
            <div className="title">
              <span className="span">*</span> 连接类型
            </div>
            <Radio.Group onChange={onChangeB} value={isSkip} className="radio">
              <Space size={35}>
                <Radio value={1}>内连</Radio>
                <Radio value={2}>图文</Radio>
                <Radio value={3}>学生认证</Radio>
              </Space>
            </Radio.Group>
          </div>
        ) : null}
        {isSkip === 2 ? (
          <div className="flex">
            <div className="title">
              <span className="span">*</span> 文本
            </div>
            <MyEditor html={html} setHtml={setHtml} />
          </div>
        ) : isSkip === 1 ? (
          <div className="flex">
            <div className="title">
              <span className="span">*</span> 选择商品
            </div>
            <Select
              showSearch
              style={{ width: 500 }}
              value={value}
              placeholder="请选择商品"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleChangeSelet}
              notFoundContent={null}
            >
              {options}
            </Select>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default BannerEdit;
