import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
  Image,
} from "antd";
import { Table } from "antd";
import "./table.less";
import React, { Key, useEffect, useState } from "react";
import CouponAPI from "@/api/modules/coupon";
interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  showModal: Function;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
  applicableDict: any;
  couponsDict: any;
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = (obj: { id: string }) => {
    props.showModal({ ...obj, look: false });
  };
  const editLookData = (obj: { id: string }) => {
    console.log(obj);
    
    props.showModal({ ...obj, look: true });
  };
  const columns: TableColumnsType<DataType> = [
    { title: "头像", dataIndex: "sculpture", key: "id",
    render: (_: any, record: any) => (
      <Image width={40} height={40} src={record.sculpture} />
    ), },
    { title: "用户名", dataIndex: "nickname", key: "id" },
    { title: "手机号", dataIndex: "phone", key: "id" },
    { title: "签到时间", dataIndex: "createTime", key: "id" }
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;
