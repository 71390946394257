import React, { useEffect, useState } from "react";
import MyEditor from "@/components/MyEditor";
import { Button, message, Select } from "antd";
import CouponAPI from "@/api/modules/coupon";
import SettingApi from "@/api/modules/settings";
type Props = {};
const { Option } = Select;
const Settings = (props: Props) => {
  const [html, setHtml] = useState("");
  const [value, setValue] = useState<string>();
  const [listData, setListData] = useState<any>([]);
  const [settingsData, setsettingsData] = useState<any>({});
  const handleSearch = (newValue: string) => {
    console.log(newValue);

    if (newValue) {
      getListData(newValue);
    } else {
      getListData("");
    }
  };
  const getListData = async (conponName: any | "") => {
    const res: any = await CouponAPI.getCouponList({
      current: 1,
      size: 20,
      conponName,
      conponType: "",
      conponUse: "",
      state: "",
    });
    console.log(res, "优惠券的列表");
    if (res.code === 200) {
      setListData([...res.data.records]);
    }
  };
  const handleChangeSelet = (newValue: string) => {
    setValue(newValue);
  };
  const options = listData.map(
    (item: { id: React.Key | null | undefined; conponName: string }) => (
      <Option key={item.id}>{item.conponName}</Option>
    )
  );
  /* 获取设置数据 */
  const getPlatList = async () => {
    const res: any = await SettingApi.getPlatList({
      size: 1,
      current: 1,
    });
    if (res.code === 200) {
      let data = res.data.records[0] || {};
      setsettingsData(data);
      setHtml(data.introduce);
      setValue(data.couponId);
    }
    console.log(res, "数据");
  };
  /* 编辑and添加 */
  const addPlatData = async () => {
    if (!html) {
      return message.error("请填写内容");
    }
    if (!value) {
      return message.error("请选择礼包");
    }
    let obj = {
      introduce: html,
      couponId: value,
    };

    if (settingsData.id) {
      const res: any = await SettingApi.upPlatData({
        id: settingsData.id,
        ...obj,
      });
      if (res.code === 200) {
        message.success("编辑成功");
      }
    } else {
      const res: any = await SettingApi.upPlatData({
        ...obj,
      });
      if (res.code === 200) {
        message.success("添加成功");
      }
    }
  };

  useEffect(() => {
    getListData("");
    getPlatList();
  }, []);
  return (
    <div>
      {/*  <div className="flex"> */}
      <span>关于我们：</span>
      <MyEditor html={html} setHtml={setHtml}></MyEditor>
      {/*  </div> */}
      <div className="flex">
        <span style={{ width: 100 }}>礼包设置</span>
        <Select
          size="large"
          showSearch
          style={{ width: 500 }}
          value={value}
          placeholder="请选择商品"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChangeSelet}
          notFoundContent={null}
        >
          {options}
        </Select>
      </div>
      <div className="se_footr">
        <Button type="primary" onClick={() => addPlatData()} size="large">
          确定
        </Button>
      </div>
    </div>
  );
};

export default Settings;
