import { Button, Form, Input, message, Modal, Select, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import {BASE_URL,MapKey}from'@/api/config'
import BusinessAPI from "@/api/modules/business";
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
  handleSuccess: any;
};
const { Option } = Select;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Popup = (props: Props) => {

  let [loading, setLoading] = useState<boolean>(false);
  /* 省 */
  let [provinceData, setProvinceData] = useState<any>([]);
  /* 市 */
  let [cities, setCities] = useState<any>([]);
  /* 区 */
  let [area, setArea] = useState<any>([]);
  /* 选择索引 */
  let [secondCity, setSecondCityValue] = useState<any>([]);
  let [areaValue, setAreaValue] = useState<any>([]);
  let [provinceValue, setProvinceValue] = useState<any>([]);

  /* 提交表单的数据 */
  let [formData, setFormData] = useState<any>({
    province: "",
    city: "",
    area: "",
  });
  /* 记录数据赋值次数 */
  const [numA, setNumA] = useState<Number>(0);
  const [numB, setNumB] = useState<Number>(0);
  const [numC, setNumC] = useState<Number>(0);
  /* 请求腾讯的key */
  let [mapObj, setMapObj] = useState<any>({
    key: MapKey,
    output: "JSON",
  });
  const [fileList, setFileList] = useState<any>([]);
  const [fileList2, setFileList2] = useState<any>([]);
  
  let addFrom: any = useRef();
  useEffect(() => {
    getMapDistrict();
    if (props.record.id) {
      setNumA(0);
      setNumB(0);
      setNumC(0);
      console.log(props.record);
      if (props.record.coverPicture) {
        setFileList([
          {
            thumbUrl: props.record.coverPicture,
            name: "xxxx",
            response: {
              code: 200,
              data: props.record.coverPicture,
            },
          },
        ]);
      }
      if(props.record.businessLicensePicture){
        setFileList2([
          {
            thumbUrl: props.record.businessLicensePicture,
            name: "xxxx",
            response: {
              code: 200,
              data: props.record.businessLicensePicture,
            },
          },
        ]);
      }
      
      setTimeout(()=>{
      addFrom.current.setFieldsValue({
        ...props.record,
      });
    },100)
    }
  }, [props.record]);

  /* 选择的省 */
  const handleProvinceChange = (value: any) => {
    console.log(value, "选择的数据");
    getMapCity(provinceData[value].id);
    setProvinceValue(value);
    setSecondCityValue([]);
    setAreaValue([]);
    formData.province = provinceData[value].name;
  };
  /* 选择市 */
  const onSecondCityChange = (value: any) => {
    console.log(cities[value].name, "选择的数据");
    setSecondCityValue(value);
    setAreaValue([]);
    getMapArea(cities[value].id);
    formData.city = cities[value].name;
  };
  /* 选择区 */
  const onSecondAreaChange = (value: any) => {
    console.log(area[value].fullname, "选择的数据");
    setAreaValue(value);
    formData.area = area[value].fullname;
  };
  /* 表单提交  同时获取逆地址编码*/
  const onFinish = async (values: any) => {
    let fist: {
      code: number | 0;
    } = { ...(fileList[0]?.response || "") };
    if (fist.code !== 200) {
      console.log(fileList, "2");
      setFileList([]);
      message.error("请上传封面图");
      return false;
    } else {
      console.log(fileList, "3");
      values.coverPicture = fileList[0].response.data;
      // values.businessLicensePicture = fileList2[0].response.data;
    }


    let fist2: {code: number | 0;} = { ...(fileList2[0]?.response || "") };
    console.log(fist2);
    
    if (fist2.code !== 200) {
      console.log(fileList2, "营业执照");
      setFileList2([]);
      message.error("请上传营业执照");
      return false;
    } else {
      console.log(fileList2, "3");
      // values.coverPicture = fileList2[0].response.data;
      values.businessLicensePicture = fileList2[0].response.data;
    }

    if (!formData.province && !formData.city) {
      message.error("请选择省市区");
      return false;
    }
    if (area.length && !formData.area) {
      message.error("请选择省市区");
      return false;
    }
    const res: any = await BusinessAPI.getMapGeocoder({
      address: decodeURI(
        formData.province + formData.city + formData.area + values.address
      ), //省
      key: mapObj.key,
    });
    if (res.status === 0) {
      values.latitude = res.result.location.lat + "";
      values.longitude = res.result.location.lng + "";
    }
    let obj = {
      ...values,
      ...formData,
    };
    obj.address = values.address;
    setLoading(true);
    if (props.record.id) {

      const req: any = await BusinessAPI.upBusinessData({
        id: props.record.id,
        oldShopPassword:props.record.shopPassword,
        oldShopUsername:props.record.shopUsername,
        ...obj,
      });
      if (req.code === 200) {
        setLoading(false);
        message.success("编辑成功");
        removeForm();
        props.handleSuccess();
        setLoading(false);
      }
    } else {
      console.log("Success:", obj);
      const req: any = await BusinessAPI.addBusinessData(obj);
      if (req.code === 200) {
        message.success("添加成功");
        removeForm();
        props.handleSuccess();
      
      }
      console.log(res);
    }
      setLoading(false);
  };

  /* 获取市数据 */
  const getMapCity = async (id: string) => {
    let obj = {
      ...mapObj,
      id,
    };
    const res: any = await BusinessAPI.getMapDistrict(obj);
    if (res.status === 0) {
      setCities([...res.result[0]]);
      if (props.record.id && numB === 0) {
        res.result[0].forEach((item: any, index: any) => {
          if (item.name === props.record.city) {
            setSecondCityValue(index + "");
            formData.city = item.name;
            getMapArea(item.id);
            setNumB(0);
          }
        });
      }
    }
  };
  /* 获取区数据 */
  const getMapArea = async (id: string) => {
    let obj = {
      ...mapObj,
      id,
    };
    const res: any = await BusinessAPI.getMapDistrict(obj);
    if (res.status === 0) {
      setArea([...res.result[0]]);
      if (props.record.id && numC === 0) {
        res.result[0].forEach((item: any, index: any) => {
          if (item.fullname === props.record.area) {
            setAreaValue(index + "");
            formData.area = item.fullname;
            setNumC(1);
          }
        });
      }
    }
  };
  /* 获取腾讯地图省级数据 */
  const getMapDistrict = async () => {
    const res: any = await BusinessAPI.getMapDistrict(mapObj);
    console.log(res, "省");

    if (res.status === 0) {
      setProvinceData([...res.result[0]]);
      if (props.record.id && numA === 0) {
        res.result[0].forEach((item: any, index: any) => {
          if (item.name === props.record.province) {
            setProvinceValue(index + "");
            getMapCity(item.id);
            formData.province = item.name;
            setNumA(1);
          }
        });
      }
    }
  };
  /* 清空表单数据 */
  const removeForm = () => {
    setFormData({
      province: "",
      city: "",
      area: "",
    });
    setNumA(0);
    setNumB(0);
    setNumC(0);
    setAreaValue([]);
    setFileList([]);
    setFileList2([]);
    setProvinceValue([]);
    setSecondCityValue([]);
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  /* 图片上传 */
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  /* 图片上传 */
  const handleCancelImg = () => setPreviewVisible(false);
  /* 图片上传 */
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      if(props.record){
        file.preview =file.thumbUrl;
      }else{
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  /* 图片上传 */
  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
    event,
  }) => {
      console.log(file, "file11111");
      console.log(event, "event");
      console.log(newFileList, "fileList");
      setFileList(newFileList);
      
/*     if (!file.response){
      message.error("文件过大,请重新上传");
    }
 */
  };
  const [previewVisible2, setPreviewVisible2] = useState(false);
  const [previewImage2, setPreviewImage2] = useState("");
  const [previewTitle2, setPreviewTitle2] = useState("");

  /* 图片上传 */
  const handleCancelImg2 = () => setPreviewVisible2(false);
  /* 图片上传 */
  const handlePreview2 = async (file: UploadFile) => {
    if(props.record){
        file.preview =file.thumbUrl;
      }else{
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      
    setPreviewImage2(file.url || (file.preview as string));
    setPreviewVisible2(true);
    setPreviewTitle2(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  /* 图片上传 */
  const handleChange2: UploadProps["onChange"] = ({
    fileList: newFileList2,
    file,
    event,
  }) => {
      console.log(file, "file22222");
      console.log(event, "event");
      console.log(newFileList2, "fileList2");
      setFileList2(newFileList2);
/*     if (!file.response){
      message.error("文件过大,请重新上传");
    }
 */
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const uploadButton2 = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  return (
    <Modal
      title={props.record?'编辑商家':'新增商家'}
      maskClosable={false}
      width={1100}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      onCancel={handleCancel}
      okText="确认"
      cancelText="取消"
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        <div className="form_box">
          <div>
            <Form.Item
              label="商家名称"
              name="shopName"
              rules={[{ required: true, message: "请输入商家名称" }]}
            >
              <Input placeholder="请输入商家名称" />
            </Form.Item>

            <Form.Item label="商家地址">
              {
                <Select
                  style={{ width: "30%" }}
                  onChange={handleProvinceChange}
                  value={provinceValue}
                  placeholder="请选择"
                >
                  {provinceData.map((item: any, index: number) => (
                    <Option key={index}>{item.name}</Option>
                  ))}
                </Select>
              }
              {
                <Select
                  style={{ width: "30%" }}
                  value={secondCity}
                  onChange={onSecondCityChange}
                  placeholder="请选择"
                >
                  {cities.map((city: any, index: number) => (
                    <Option key={index}>{city.name}</Option>
                  ))}
                </Select>
              }
              {
                <Select
                  style={{ width: "30%" }}
                  value={areaValue}
                  onChange={onSecondAreaChange}
                  placeholder="请选择"
                >
                  {area.map((city: any, index: number) => (
                    <Option key={index}>{city.fullname}</Option>
                  ))}
                </Select>
              }
            </Form.Item>
            <Form.Item
              label="详细地址"
              name="address"
              rules={[{ required: true, message: "请输入详细地址" }]}
            >
              <Input placeholder="请输入详细地址" />
            </Form.Item>
            <Form.Item label="商家封面图">
              <Upload
                action={
                  BASE_URL +
                  "/api/open/aliOssFile/upload?filePath=image/&resType=1"
                }
                accept="image/png, image/jpeg"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                headers={{
                  TOKEN: sessionStorage.getItem("token") || "",
                }}
                name="file"
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancelImg}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
            <Form.Item label="上传营业执照">
              <Upload
                action={
                  BASE_URL +
                  "/api/open/aliOssFile/upload?filePath=image/&resType=1"
                }
                accept="image/png, image/jpeg"
                listType="picture-card"
                fileList={fileList2}
                onPreview={handlePreview2}
                headers={{
                  TOKEN: sessionStorage.getItem("token") || "",
                }}
                name="file"
                onChange={handleChange2}
              >
                {fileList2.length >= 1 ? null : uploadButton2}
              </Upload>
              <Modal
                visible={previewVisible2}
                title={previewTitle2}
                footer={null}
                onCancel={handleCancelImg2}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage2}
                />
              </Modal>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="负责人"
              name="shopCadre"
              rules={[{ required: true, message: "请填写负责人" }]}
            >
              <Input placeholder="请填写负责人" />
            </Form.Item>

            <Form.Item
              label="联系电话"
              name="contactPhone"
              rules={[{ required: true, message: "请输入商家联系电话" }]}
            >
              <Input placeholder="请输入联系电话" />
            </Form.Item>
            <Form.Item
              label="账号"
              name="shopUsername"
              rules={[{ required: true, message: "请输入商家账号" }]}
            >
              <Input placeholder="请输入账号" />
            </Form.Item>
            <Form.Item
              label="密码"
              name="shopPassword"
              rules={[{ required: true, message: "请输入商家密码" }]}
            >
              <Input placeholder="请输入密码" />
            </Form.Item>
          </div>
        </div>

        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>{" "}
    </Modal>
  );
};

export default Popup;
