import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
} from "antd";
import { Table } from "antd";
import "./table.less";
import { Key } from "react";
import BannerAPi from "@/api/modules/banner";
import { useNavigate } from "react-router-dom";

interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
};
const TableList = (props: Props) => {
  const navigate = useNavigate();
  const { total, page, setPage } = props;
  const onSwitchChange = async (checked: number, event: any) => {
    const res: any = await BannerAPi.updateStatement({
      id: event.id,
      state: checked ? 0 : 1,
    });
    console.log(res);

    if (res.code === 200) {
      message.success(res.msg);
      props.handleSuccess();
    }
  };
  const deleteDictById = async (data: { id: string }) => {
    console.log(data.id);
    const res: any = await BannerAPi.deleteBannerById(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };

  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = async (obj: { id: string }) => {
    navigate(`/home/banner/edit?id=${obj.id}`);

    /* ; */
  };
  const columns: TableColumnsType<DataType> = [
    { title: "排序", dataIndex: "sort", key: "id" },
    { title: "banner名称", dataIndex: "name", key: "id" },
    {
      title: "图片",
      dataIndex: "conponType",
      key: "id",
      render: (_: any, record: any) => (
        <img src={record.imgUrl} alt="" width={200} height={100} />
      ),
    },
    {
      title: "类型",
      dataIndex: "conponUse",
      key: "id",
      render: (_: any, record: any) => (
        <span>
          {record.isSkip ? (record.isSkip === 1 ? "内部链接" :record.isSkip === 2?'图文':'学生认证') : "无"}
        </span>
      ),
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            <Switch
              checkedChildren="启用"
              unCheckedChildren="禁用"
              defaultChecked={record.disable === 0}
              key={record.id}
              onChange={() => {
                onSwitchChange(record.disable, record);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editData(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定删除轮播图吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
