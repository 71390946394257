import { Button, Form, Input, message, Modal } from "antd";
import { useRef, useState, useEffect } from "react";
import BrandApi from "@/api/modules/brand";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
  handleSuccess: any;
};

const Popup = (props: Props) => {
  let [loading, setLoading] = useState<boolean>(false);
  const addFrom: any = useRef();
  useEffect(() => {
    if (props.record.id) {
      setTimeout(() => {
        addFrom.current.setFieldsValue({
          ...props.record,
        });
      }, 0);
     
    }
  }, [props.record]);

  /* 表单提交 */
  const onFinish = async (values: any) => {
    let obj = {
      ...values,
    };
     setLoading(true);
    if (props.record.id) {
      const req: any = await BrandApi.updateById({
        id: props.record.id,
        brandState: props.record.brandState,
        ...obj,
      });
      if (req.code === 200) {
        message.success("编辑成功");
        removeForm();
        props.handleSuccess();
     
      }
    } else {
      console.log("Success:", obj);
      const req: any = await BrandApi.addData({
        brandState: 0,
        ...obj,
      });
      if (req.code === 200) {
     
        message.success("添加成功");
        removeForm();
        props.handleSuccess();
       
      }
    }
    setLoading(false);
  };

  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={props.record ? "编辑品牌" : "新增品牌"}
      maskClosable={false}
      width={600}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      onCancel={handleCancel}
      footer={false}
      okText="确认"
      cancelText="取消"
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        <Form.Item
          label="品牌名称"
          name="brandName"
          rules={[{ required: true, message: "请输入品牌名称" }]}
        >
          <Input placeholder="请输入品牌名称" />
        </Form.Item>

        <Form.Item
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          label="品牌描述"
          name="brandInfo"
          rules={[{ required: true, message: "请输入品牌描述" }]}
        >
          <Input.TextArea
            style={{ height: 120 }}
            maxLength={250}
            placeholder="请输入品牌描述"
          />
        </Form.Item>
        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Popup;
