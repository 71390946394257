import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
} from "antd";
import { Table } from "antd";
import "./table.less";
import { Key } from "react";
import GOODSAPI from "@/api/modules/goods";
import { useNavigate } from "react-router-dom";

interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
};
const TableList = (props: Props) => {
  const navigate = useNavigate();
  const { total, page, setPage } = props;
  const onSwitchChange = async (checked: number, event: any) => {
    let res: any = "";
    if (checked) {
      res = await GOODSAPI.putDataOnline({ id: event.id });
    } else {
      res = await GOODSAPI.putDataOffline({ id: event.id });
    }
    if (res.code === 200) {
      message.success(res.msg);
      props.handleSuccess();
    }
  };
  const deleteDictById = async (data: { id: string }) => {
    console.log(data.id);
    const res: any = await GOODSAPI.deleteDataById(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };

  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = async (obj: { id: string }) => {
    navigate(`/home/goodsEdit?id=${obj.id}`);
  };
  /* 推荐 */
  const onSwitchReferee = async (checked: number, event: any) => {
    let res: any = "";
    if (checked) {
      res = await GOODSAPI.putDataOnReferee({ id: event.id });
    } else {
      res = await GOODSAPI.putDataOffReferee({ id: event.id });
    }
    if (res.code === 200) {
      message.success(res.msg);
      props.handleSuccess();
    }
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: "封面图",
      dataIndex: "proName",
      key: "id",
      render: (_: any, record: any) => (
        <div>
          <img src={record.proPicture} alt="" width={50} height={50} />
        </div>
      ),
    },
    {
      title: "商品名称",
      dataIndex: "proName",
      key: "id",
    },
    { title: "类型", dataIndex: "brandName", key: "id" },
/*     {
      title: "卖价",
      dataIndex: "proSalePrice",
      key: "id",
    }, */
/*     {
      title: "活动价",
      dataIndex: "proActivityPrice",
      key: "id",
    }, */
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "id",
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            <Switch
              checkedChildren="上架"
              unCheckedChildren="下架"
              defaultChecked={record.onlineState === 0}
              key={record.id}
              onChange={() => {
                onSwitchChange(record.onlineState, record);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "推荐",
      dataIndex: "state",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            <Switch
              checkedChildren="推荐"
              unCheckedChildren="取消"
              defaultChecked={record.refereeState === 0}
              key={record.id}
              onChange={() => {
                onSwitchReferee(record.refereeState, record);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editData(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定删除商品吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
