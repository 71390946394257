import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
} from "antd";
import { Table } from "antd";
import "./table.less";
import React, { Key, useEffect, useState } from "react";
import CouponAPI from "@/api/modules/coupon";
interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  setListData: Function;
  listData: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
  couponsDict:any
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;

  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const columns: TableColumnsType<DataType> = [
    { title: "订单编号", dataIndex: "orderNo", key: "id" },
    { title: "用户昵称", dataIndex: "userName", key: "id" },
    { title: "联系电话", dataIndex: "userPhone", key: "userPhone" },
    { title: "商品名称", dataIndex: "productName", key: "id" },
    {
      title: "类型",
      dataIndex: "type",
      key: "id",
      render: (_: any, record: any) => (
        <span>
          {props.couponsDict
            .filter((item: { code: any }) => {
              return (item.code-0) === (record.type-0);
            })
            .map((em: { name: any }) => em.name)}
        </span>
      ),
    },
    { title: "价格", dataIndex: "price", key: "id" },
    { title: "数量", dataIndex: "num", key: "id" },
    { title: "创建时间", dataIndex: "createTime", key: "id" },
    {
      title: "状态",
      dataIndex: "state",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <span>{record.state?record.state===1?"已支付":record.state===2?"已取消":record.state===3?"已退款":'已过期':'待支付'}</span>
        );
      },
    },
/*     {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editData(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定删除优惠券吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    }, */
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
