import { BASE_URL } from "@/api/config";
import BrandApi from "@/api/modules/brand";
import ClassApi from "@/api/modules/class";
import MyEditor from "@/components/MyEditor";
import { PlusOutlined } from "@ant-design/icons";
import { Modal} from "antd";
import type { InputRef } from "antd";
import {
  Button,
  Input,
  message,
  Select,
  Space,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./banneredit.less";
import TableList from "./components/formatTable";
import GOODSAPI from "@/api/modules/goods";
import Popup from "./components/popup";
const { Option } = Select;
type Props = {};
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function GoodsEdit({}: Props) {
  const [fileList, setFileList] = useState<any>([]);
  const [fileList1, setFileList1] = useState<any>([]);
  /* 图片上传 */
  const [html, setHtml] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const nameRef = useRef<any>();
  const [bannername, setBannername] = useState<any>("");
  const [id, setId] = useState<any>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const [brandValue, setBrandValue] = useState<string>("");
  const [typeValue, setTypeValue] = useState<string>("");
  const [brandPage, setBrandPage] = useState<any>({
    current: 1,
    size: 20,
  });
  const [classPage, setClassPage] = useState<any>({
    current: 1,
    size: 20,
    sortState: "",
  });
  const [brandList, setBrandList] = useState<any>([]);
  const [classList, setClassList] = useState<any>([]);
  //规格数据
  const [sublistData, setSublistData] = useState<Array<any>>([]);
  const [infoData, setInfoData] = useState<any>({});
  useEffect(() => {
    /* 获取优惠券列表 */

    // eslint-disable-next-line no-restricted-globals
    let id = location.href.split("id=")[1];
    setId(id);
    if (id) {
      getBannerByIdData(id);
    }

    getBrandList("");
    geClassList("");
  }, []);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };
  /* 封面图 */
  const navigate = useNavigate();
  const handlePreview = async (file: UploadFile) => {
   if (!file.url && !file.preview) {
      if(id){
        file.preview =file.thumbUrl;
      }else{
file.preview = await getBase64(file.originFileObj as RcFile);
      }
      
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  /* 轮播图 */
  const handlePreview1 = async (file: UploadFile) => {
   
    
    if (!file.url && !file.preview) {
      if(id){
        file.preview =file.thumbUrl;
      }else{
file.preview = await getBase64(file.originFileObj as RcFile);
      }
      
    }
    console.log(file,'我是假面骑士');
    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  /* 封面图片上传 */
  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
    event,
  }) => {
    setFileList(newFileList);
    /*     if (!file.response){
      message.error("文件过大,请重新上传");
    }
 */
  };
  /* 轮播图上传 */
  const handleChange1: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
    event,
  }) => {
    setFileList1(newFileList);
    /*     if (!file.response){
      message.error("文件过大,请重新上传");
    }
 */
  };
  /* 根据id 查询详情 */
  const getBannerByIdData = async (id: string) => {
    const res: any = await GOODSAPI.getByIdData({ id });

    if (res.code === 200) {
      setHtml(res.data.proDetails);
      setBannername(res.data.proName);
      setBrandValue(res.data.brandId);
      setTypeValue(res.data.proType);
      setSublistData(res.data.normsVOS);
      setTags(res.data.proServer.split(","));
      setFileList([
        {
          thumbUrl: res.data.proPicture,
          name: "xxxx",
          response: {
            code: 200,
            data: res.data.proPicture,
          },
        },
      ]);
      let imgArr = res.data.proBanner.split(",");
      const obj = imgArr.map((item: any, index: any) => {
        return {
          thumbUrl: item,
          name: index + "xxxx",
          response: {
            code: 200,
            data: item,
          },
        };
      });
      setFileList1([...obj]);
      setInfoData({ ...res.data });
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );

  const handleSearchBrand = (newValue: string) => {
    if (newValue) {
      getBrandList(newValue);
    } else {
      getBrandList("");
    }
  };
  const handleSearchType = (newValue: string) => {
    if (newValue) {
      geClassList(newValue);
    } else {
      geClassList("");
    }
  };
  const handleChangeSeletBrand = (newValue: string) => {
    setBrandValue(newValue);
  };
  const handleChangeSeletType = (newValue: string) => {
    setTypeValue(newValue);
  };
  // eslint-disable-next-line react/jsx-no-undef
  const options = brandList.map(
    (item: { id: React.Key | null | undefined; brandName: string }) => (
      <Option key={item.id}>{item.brandName}</Option>
    )
  );
  const optionsB = classList.map(
    (item: { id: React.Key | null | undefined; sortName: string }) => (
      <Option key={item.id}>{item.sortName}</Option>
    )
  );
  /* 发起提交 */
  const addBannerData = async () => {
    let imgUrl = fileList.length
      ? fileList[0].response?.code === 200
        ? fileList[0].response.data
        : ""
      : "";
    let imgUrlb = fileList1.every((item: { response: { code: number } }) => {
      return item.response?.code === 200;
    });

    if (!imgUrl) {
      message.error("请上传合格的图片");
      setFileList([]);

      return false;
    }
    if (!imgUrlb) {
      message.error("请上传正确的轮播图");
      return false;
    }
    if (sublistData.length === 0) {
      message.error("规格不能为空");
      return false;
    }
    if (!bannername) {
      message.error("商品名称不能为空");
      return false;
    }
    if (!brandValue) {
      message.error("请选择商品品牌");
      return false;
    }
    if (!typeValue) {
      message.error("请选择商品分类");
      return false;
    }
    if (!html) {
      message.error("请输入商品介绍");
      return false;
    }
    let obj: any = {
      normsDTOs: sublistData, //规格表
      proName: bannername || "", //商品名称
      brandId: brandValue, //品牌
      onlineState: 0,
      refereeState: 1,
      proType: typeValue,
      proPicture: fileList[0].response.data,
      proBanner: fileList1
        .map((item: { response: { data: any } }) => item.response.data)
        .join(","),
      proServer: tags.join(","),
      proDetails: html,
      proReserve: sublistData.reduce((acc, cur) => {
        return acc - 0 + (cur.stock - 0);
      }, 0),
    };

    if (id) {
      obj.onlineState = infoData.onlineState;
      obj.refereeState = infoData.refereeState;
      const res: any = await GOODSAPI.updeatDataById({ id: id, ...obj });
      if (res.code === 200) {
        message.success(res.msg);
        navigate("/home/goodsList");
      }
    } else {
      const res: any = await GOODSAPI.addGoodsData(obj);

      if (res.code === 200) {
        message.success(res.msg);
        navigate("/home/goodsList");
      }
    }
  };
  const handleCancelImg = () => setPreviewVisible(false);
  /* 输入名字存储 */
  const bannernameChage = (e: any) => {
    setBannername(e.target.value);
  };
  /* 获取品牌列表 */
  const getBrandList = async (brandName: string) => {
    const res: any = await BrandApi.getList({ ...brandPage, brandName });
    if (res.code === 200) {
      setBrandList([...res.data.records]);
    }
  };

  /* 获取类型列表 */
  const geClassList = async (sortName: any = "") => {
    const res: any = await ClassApi.getClassList({ ...classPage, sortName });
    if (res.code === 200) {
      setClassList([...res.data.records]);
    }
  };
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<Object | string>("");
  /* 添加规格 */
  const addSpecifications = () => {
    setRecord("");
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSuccess = () => {
    setIsModalVisible(false);
  };
  const [page, setPage] = useState<any>({});

  return (
    <div className="edit_box">
      <Popup
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        record={record}
        handleSuccess={handleSuccess}
        setSublistData={setSublistData}
        sublistData={sublistData}
      ></Popup>
      <div className="foot goodsFoot">
        <h2>{id ? "编辑商品" : "新增商品"}</h2>
        <Space size={20}>
          <Button type="primary" onClick={addBannerData}>
            确认
          </Button>
          <Button
            onClick={() => {
              navigate("/home/goodsList");
            }}
          >
            取消
          </Button>
        </Space>
      </div>
      <div className="min">
        <div className="zw_box"></div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> 商品
          </div>
          <Input
            placeholder="请输入商品名称"
            ref={nameRef}
            value={bannername}
            onInput={bannernameChage}
            size="large"
          />
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> 所属类型
          </div>
          <Select
            showSearch
            style={{ width: 500 }}
            size="large"
            value={typeValue}
            placeholder="选择类型（输入文字搜索）"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchType}
            onChange={handleChangeSeletType}
            notFoundContent={null}
          >
            {optionsB}
          </Select>
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> 所属品牌
          </div>
          <Select
            showSearch
            style={{ width: 500 }}
            size="large"
            value={brandValue}
            placeholder="选择品牌（输入文字搜索）"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchBrand}
            onChange={handleChangeSeletBrand}
            notFoundContent={null}
          >
            {options}
          </Select>
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> 封面图
          </div>
          <Upload
            action={
              BASE_URL + "/api/open/aliOssFile/upload?filePath=image/&resType=1"
            }
            accept="image/png, image/jpeg"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            headers={{
              TOKEN: sessionStorage.getItem("token") || "",
            }}
            name="file"
            onChange={handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancelImg}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={fileList[0]?.thumbUrl?fileList[0].thumbUrl:previewImage}
                />
              </Modal>
        </div>

        <div className="flex">
          <div className="title">
            <span className="span">*</span> 轮播图
          </div>
          <Upload
            action={
              BASE_URL + "/api/open/aliOssFile/upload?filePath=image/&resType=1"
            }
            accept="image/png, image/jpeg"
            listType="picture-card"
            fileList={fileList1}
            onPreview={handlePreview1}
            headers={{
              TOKEN: sessionStorage.getItem("token") || "",
            }}
            name="file"
            onChange={handleChange1}
          >
            {fileList1.length >= 9 ? null : uploadButton}
          </Upload>
        </div>

        <div className="">
          <div className="title flex gui_gei_box">
            <div>
              <span className="span">*</span> 规格:
            </div>
            <div>
              <Tag
                className="site-tag-plus"
                color="#1DA57A"
                onClick={addSpecifications}
              >
                <PlusOutlined /> 添加规格
              </Tag>
            </div>
          </div>
          <TableList
            sublistData={sublistData}
            setSublistData={setSublistData}
            handleSuccess={handleSuccess}
            setPage={setPage}
            setRecord={setRecord}
            page={page}
            setIsModalVisible={setIsModalVisible}
          />
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> 服务
          </div>
          <div>
            {tags.map((tag, index) => {
              if (editInputIndex === index) {
                return (
                  <Input
                    ref={editInputRef}
                    key={tag}
                    size="large"
                    className="tag-input"
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                  />
                );
              }

              const isLongTag: any = tag.length > 20;

              const tagElem = (
                <Tag
                  className="edit-tag"
                  key={tag}
                  closable={true}
                  onClose={() => handleClose(tag)}
                >
                  <span
                    onDoubleClick={(e) => {
                      if (index !== 0) {
                        setEditInputIndex(index);
                        setEditInputValue(tag);
                        e.preventDefault();
                      }
                    }}
                  >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </span>
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
            {inputVisible && (
              <Input
                ref={inputRef}
                type="text"
                className="tag-input"
                maxLength={4}
                placeholder="最长4个字"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            )}
            {!inputVisible && (
              <Tag
                className="site-tag-plus"
                color="#1DA57A"
                onClick={showInput}
              >
                <PlusOutlined /> 添加服务
              </Tag>
            )}
          </div>
        </div>
        <div className="flex">
          <div className="title">
            <span className="span">*</span> 详情介绍
          </div>
          <MyEditor html={html} setHtml={setHtml} />
        </div>
      </div>
    </div>
  );
}

export default GoodsEdit;
