import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
} from "antd";
import { Table } from "antd";
import "./table.less";
import { Key } from "react";
// import BannerAPi from "@/api/modules/banner";
import SelfLiftingAPI from "@/api/modules/selfLifting";
import { useNavigate } from "react-router-dom";

interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
};
const TableList = (props: Props) => {
  const navigate = useNavigate();
  const { total, page, setPage } = props;
  const deleteDictById = async (data: { id: string }) => {
    // console.log(data.id);
    const res: any = await SelfLiftingAPI.deleteAddressById(data);
    // console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };

  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = async (obj: { id: string }) => {
    navigate(`/home/banner/edit?id=${obj.id}`);

    /* ; */
  };
  const columns: TableColumnsType<DataType> = [
    { title: "编号", dataIndex: "id", key: "id" },
    { title: "设置时间", dataIndex: "createTime", key: "id" },
    {
      title: "自提点",
      dataIndex: "address",
      key: "id"
    },
   
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          
          <Popconfirm
            title="确定删除自提点吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
