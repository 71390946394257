import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
} from "antd";
import { Table } from "antd";
import "./table.less";
import { Key } from "react";
import BannerAPi from "@/api/modules/banner";
import { useNavigate } from "react-router-dom";

interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
  typeName:any;
};
const TableList = (props: Props) => {
  const navigate = useNavigate();
  const { total, page, setPage,typeName } = props;
  const onSwitchChange = async (checked: number, event: any) => {
    const res: any = await BannerAPi.updateStatement({
      id: event.id,
      state: checked ? 0 : 1,
    });
    console.log(res);

    if (res.code === 200) {
      message.success(res.msg);
      props.handleSuccess();
    }
  };
  // const deleteDictById = async (data: { id: string }) => {
  //   console.log(data.id);
  //   const res: any = await BannerAPi.deleteBannerById(data);
  //   console.log(res);
  //   if (res.code === 200) {
  //     message.success("删除成功");
  //     props.handleSuccess();
  //   }
  // };

  // const cancel = (e: any) => {
  //   console.log(e);
  //   message.error("取消");
  // };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };

  const columns: TableColumnsType<DataType> = [
    { title: "返佣类型", dataIndex: "sort", key: "id" ,
    render: (_: any, record: any,index:any) => (
      <div>
        {typeName}
      </div>
    ),},
    { title: "设置时间", dataIndex: "updateTime", key: "id" },
    { title: "网格员(%)", dataIndex: "vipUserCommissionValue", key: "id" },
    { title: "普通用户(%)", dataIndex: "userCommissionValue", key: "id" }
   
   
  
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
