import { service, BASE_URL } from "../request";
import { OrderPage } from "@/utils/tsModules/coupon";
const OrderAPI = {
    getCouponList(params: OrderPage) {
        return service({
            url: `${BASE_URL}/api/admin/order/orderSub/list`,
            params
        });
    },
  /* 获取核销订单表 */
  getOrderWriteOff(params: any) {
    return service({
        url: `${BASE_URL}/api/admin/order/orderWriteOff/list`,
        params
    });
},
/* 获取下载表 */
getExportExecl(params: any) {
    return service({
        url: `${BASE_URL}/api/admin/order/orderWriteOff/exportExecl`,
        params,
        responseType: 'arraybuffer',
    });
},
}
export default OrderAPI;