import { Button, Form, Input, Modal, Image, message } from "antd";
import { useRef, useState, useEffect } from "react";
// import userAPI from "@/api/modules/user";
import withdrawalAPI from "@/api/modules/withdrawal"
import "moment/locale/zh-cn";
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
  handleSuccess: any;
  applicableDict: any;
  couponsDict: any;
  lableDict: any;
};

const Popup = (props: Props) => {
  let [loading, setLoading] = useState<boolean>(false);
  let [stuRemarks, setStuRemarks] = useState<string>("");
  const addFrom: any = useRef();
  useEffect(() => {
    setStuRemarks(props.record.stuRemarks);
  }, []);
  /* 审核通过*/
  const onFinish = async () => {
    setLoading(true);
    const res: any = await withdrawalAPI.withdrawal({
      id: props.record.id,
      stuRemarks: stuRemarks||'',
      state:2
    });
    if (res.code === 200) {
     
      message.success("审核完成");
      removeForm();
      props.handleSuccess()
    }
    setLoading(false);
  };

  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
    
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel()
  };
  const handleErrar = async () => {
    if (!stuRemarks) {
      return message.error("请填写未通过原因");
    }
    setLoading(true);
    const res: any = await withdrawalAPI.withdrawal({
      id: props.record.id,
      stuRemarks: stuRemarks||'',
      state:3
    });
    if (res.code === 200) {
      message.success("审核完成");
      setLoading(false);
      removeForm();
      props.handleSuccess()
    }
  };
  /* 获取备注数据 */
  const inputTextArea = (e: any) => {
    if (e.target.value.length <= 20) {
      setStuRemarks(e.target.value);
    }
  };
  return (
    <Modal
      title={props.record.look ? "详情" : "审核"}
      maskClosable={false}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      onCancel={handleCancel}
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        size="large"
      >
        <Form.Item label="昵称">
          <span>{props.record.userName}</span>
        </Form.Item>
        <Form.Item label="电话">
          <span>{props.record.userPhone}</span>
        </Form.Item>
        <Form.Item label="提现金额">
          <span>{props.record.amount}元</span>
        </Form.Item>
        <Form.Item label="申请时间">
          <span>{props.record.createTime}</span>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          label="备注"
        >
          <Input.TextArea
            disabled={props.record.look}
            showCount
            value={stuRemarks}
            maxLength={20}
            placeholder="请输入备注"
            onChange={inputTextArea}
          />
        </Form.Item>

        {props.record.look ? null : (
          <div className="tab_but">
            <Button size="middle" onClick={handleErrar}>
              不通过
            </Button>
            <Button
              type="primary"
              size="middle"
              loading={loading}
              onClick={onFinish}
            >
              通过
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default Popup;
