import GOODSAPI from "@/api/modules/goods";
import { ListData } from "@/utils/tsModules/goods";
import { Button, Input, Select, Space } from "antd";
import { Key, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableList from "./components/Table";
import "./index.less";
const GoodsList = () => {
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const conponNameRef = useRef<any>();
  const [conponState, setConponState] = useState<any>("");
  const navigate = useNavigate();
  const [page, setListPage] = useState<ListData>({
    current: 1,
    size: 10,
    proName: "",
    onlineState: "",
  });
  useEffect(() => {
    getListData();
  }, [page]);

  const getListData = async () => {
    setLoading(true);
    const res: any = await GOODSAPI.getList(page);
    console.log(res, "优惠券的列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: Object) => {
    navigate("/home/goodsEdit");
  };

  const handleSuccess = () => {
    setIsModalVisible(false);
    getListData();
  };
  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      proName: conponNameRef.current.input.value,
      onlineState: conponState === 0 || conponState ? conponState : "",
    };
    console.log(obj);

    setListPage({ ...obj });
  };
  const [seletData, setSeletData] = useState<any>([
    { state: 0, name: "上架" },
    { state: 1, name: "下架" },
  ]);
  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };

  const options = seletData.map(
    (item: { state: Key | null | undefined; name: string }) => (
      <Option key={item.state} value={item.state}>
        {item.name}
      </Option>
    )
  );
  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="商品名称" ref={conponNameRef} allowClear />
        <Select placeholder="状态" onChange={changeConponState} allowClear>
          {options}
        </Select>
        <Button onClick={searchInPut} size="middle">
          查询
        </Button>
        <Button
          type="primary"
          onClick={() => {
            showModal("");
          }}
          size="middle"
        >
          新增
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
        ></TableList>
      }
    </div>
  );
};

export default GoodsList;
