import TableList from "./components/Table";
import Popup from "./components/popup";
import { Button, Input, Select, Space } from "antd";
import { useState, useEffect, useRef } from "react";
import CouponAPI from "@/api/modules/coupon";
import Dict from "@/api/modules/dict";
import { ListData } from "@/utils/tsModules/coupon";
import "./index.less";
type Props = {};

const Coupon = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>("");
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const conponNameRef = useRef<any>();
  const [page, setListPage] = useState<ListData>({
    current: 1,
    size: 10,
    conponName: "",
    conponType: "",
    conponUse: "",
    state: "",
  });
  const { Option } = Select;
  /* 存储适用类型字典 */
  const [applicableDict, setApplicableDict] = useState<any>([]);
  /* 存储 券类型*/
  const [couponsDict, setCouponsDict] = useState<any>([]);
  const [conponUse, setConponUse] = useState<string>("");
  const [conponState, setConponState] = useState<number|string>("");
  const [conponType, setConponType] = useState<string>("");
  const [lableDict, setLableDict] = useState<any>([]);
  useEffect(() => {
    getListData();
  }, [page]);
  useEffect(() => {
    /* 获取适用类型 */
    getDictList();
  }, []);

  const getListData = async () => {
    setLoading(true);
    const res: any = await CouponAPI.getCouponList(page);
    console.log(res, "优惠券的列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: object) => {
    setRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setRecord("");
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setRecord("");
    setIsModalVisible(false);
    getListData();
  };
  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      conponUse: conponUse || "",
      conponType: conponType || "",
      conponName: conponNameRef.current.input.value || "",
      state: conponState === 0 || conponState  ? conponState : "",
    };
    console.log(obj);

    setListPage({ ...obj });
  };
  /* 获取字典 */
  const getDictList = async () => {
    const res: any = await Dict.getDictionaryList({});
    console.log(res, "字典");
    if (res.code === 200) {
      const APPLICABLE_TYPE: any = res.data.filter((item: any) => {
        return item.code === "APPLICABLE_TYPE";
      })[0];
      const CONPON_TYPE: any = res.data.filter((item: any) => {
        return item.code === "CONPON_TYPE";
      })[0];

      const LABLE_TYPE: any = res.data.filter((item: any) => {
        return item.code === "LABLE_TYPE";
      })[0];
      setLableDict([...LABLE_TYPE.dictList]);
      setApplicableDict([...APPLICABLE_TYPE.dictList]);
      setCouponsDict([...CONPON_TYPE.dictList]);
    }
  };

  const changeType = (e: any) => {
    setConponType(e);
  };
  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };
  const changeConponUse = (e: any) => {
    setConponUse(e);
  };
  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="餐券名称" ref={conponNameRef} allowClear />
        <Select allowClear placeholder="券类型" onChange={changeType}>
          {couponsDict.map((item: any) => (
            <Option key={item.code} value={item.code}>
              {item.name}
            </Option>
          ))}
        </Select>
        <Select allowClear placeholder="适用类型" onChange={changeConponUse}>
          {applicableDict.map((item: any) => (
            <Option key={item.code} value={item.code}>
              {item.name}
            </Option>
          ))}
        </Select>
        <Select placeholder="状态" onChange={changeConponState} allowClear>
          <Option value={0}>上架</Option>
          <Option value={1}>下架</Option>
        </Select>
        <Button onClick={searchInPut} size="middle">
          查询
        </Button>
        <Button
          type="primary"
          onClick={() => {
            showModal({});
          }}
          size="middle"
        >
          新增
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          showModal={showModal}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
          applicableDict={applicableDict}
          couponsDict={couponsDict}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible}
        record={record}
        applicableDict={applicableDict}
        couponsDict={couponsDict}
        lableDict={lableDict}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
        listData={listData}
      ></Popup>
    </div>
  );
};

export default Coupon;
