import { service, BASE_URL } from "../request";
const SystemApi = {
    /* 获取角色列表 */
    getRoleList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/role/list`,
            params
        });
    },
    /* 获取菜单列表 */
    getMenuList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/menu/list`,
            params
        });
    },
    /* 添加菜单 */
    addMenuData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/menu`,
            method: 'POST',
            data
        });
    },
    /* 编辑菜单 */
    putMenuData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/menu/${data.id}`,
            method: 'PUT',
            data
        });
    },
    /* 删除菜单 */
    deleteMenuData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/menu/${data.id}`,
            method: 'DELETE',
            data
        });
    },
    /* 菜单启用禁用 */
    updateStateMenuData(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/menu/updateState/${params.id}`,
            method: 'PUT',
            params
        });
    },
    /* 获取角色关联的菜单 */
    getRoleMenuDataList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/role/menu/${params.id}`,
            method: 'GET',

        });
    },
    /* 给角色关联菜单 */
    addRoleMenuDataList(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/role/updRoleMenu`,
            method: 'PUT',
            data
        });
    },
    /* 根据id 查询角色详情 */
    getRoleByIdData(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/role/${params.id}`,
            method: 'GET',
            params
        });
    },
    /* 添加角色 */
    addRoleByIdData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/role`,
            method: 'POST',
            data
        });
    },
    /* 编辑角色 */
    putRoleByIdData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/role/${data.id}`,
            method: 'PUT',
            data
        });
    },
    /* 删除角色 */
    deleteRoleData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/role/${data.id}`,
            method: 'DELETE',
            data
        });
    },
    /* 获取当前用户的菜单树 */
    getTokenMenuTree(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/menu/findTree`,
            params
        });
    },
}
export default SystemApi;