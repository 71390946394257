import { service, BASE_URL } from "../request";
const BrandApi = {
    /* 获取品牌列表 */
    getList(params: { current: number, size: number }) {
        return service({
            url: `${BASE_URL}/api/admin/product/brand/list`,
            params
        });
    },
    /* 上架 */
    putOnline(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/brand/online/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 下架 */
    putOffline(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/brand/offline/${params.id}`,
            params,
            method: 'PUT'
        });
    },

    /* 删除 */
    deleteById(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/brand/${params.id}`,
            params,
            method: 'DELETE'
        });
    },
    /* 添加 */
    addData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/product/brand`,
            data,
            method: 'POST'
        });
    },
    /* 编辑 */
    updateById(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/product/brand/${data.id}`,
            data,
            method: 'PUT'
        });
    },
}
export default BrandApi;