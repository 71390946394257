import { service, BASE_URL } from "../request";
const InterestRateApi = {
    /* 获取利率列表 */
    getRateList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/integral/interestRate/list`,
            params
        });
    },
    /* 根据ID修改利率列表 */
    updateRateById(data:{userCommissionValue:number,vipUserCommissionValue:number,interestRateType:number}) {
        return service({
            url: `${BASE_URL}/api/admin/integral/interestRate/updeteProportion`,
            data,
            method:'PUT'
        });
    },
   
}
export default InterestRateApi;