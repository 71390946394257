import { service, BASE_URL } from "../request";
const userAPI = {
    /* 获取用户列表 */
    getUserList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/user/list`,
            params
        });
    },
    /* 用户启用禁用 */
    updateUserState(params: any) {
        return service({
            url: BASE_URL + `/api/admin/user/updateState/${params.id}`,
            method: "PUT",
            params
        });
    },
    /* 获取审核列表 */
    getExamineList(params: any) {
        return service({
            url: BASE_URL + `/api/admin/student/student/list`,
            params
        });
    },
    /* 审核通过 */
    putExamineAdopt(params: any) {
        return service({
            url: BASE_URL + `/api/admin/student/student/success/${params.id}/${params.stuRemarks}`,
            method: "PUT",
            params
        });
    },
    /* 不通过 */
    putExamineFailed(params: any) {
        return service({
            url: BASE_URL + `/api/admin/student/student/fail/${params.id}/${params.stuRemarks}`,
            method: "PUT",
            params
        });
    },
    /* 删除管理端账号 */
    deleteAdminUser(params: any) {
        return service({
            url: BASE_URL + `/api/admin/user/${params.id}`,
            method: "DELETE",
        });
    },
    /* 添加管理端账号 */
    addAdminUser(data: any) {
        return service({
            url: BASE_URL + `/api/admin/user`,
            method: "POST",
            data
        });
    },
    /* 编辑管理端账号 */
    putAdminUserById(data: any) {
        return service({
            url: BASE_URL + `/api/admin/user/${data.id}`,
            method: "PUT",
            data
        });
    },
    /* 重置密码 */
    putresetPasswordById(params: any) {
        return service({
            url: BASE_URL + `/api/admin/user/${params.id}/resetPassword`,
            method: "PUT",
            params
        });
    }
}
export default userAPI;