import userAPI from "@/api/modules/user";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Spin,
  Switch,
  Table,
  TableColumnsType,
} from "antd";
import { Key, useRef, useState } from "react";
import "./table.less";
interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  setListData: Function;
  listData: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
  isModalVisible: boolean;
  handleSuccess: Function;
  setIsModalVisible: Function;
  setRecord: Function;
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;
  let [loading, setLoading] = useState<boolean>(false);
  const [isModalVisiblePwd, setIsModalVisiblePwd] = useState<boolean>(false);
  const [recordData, setRecord] = useState<{ id: string }>({
    id: "",
  });
  const pwdFrom: any = useRef();
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  /* 启用禁用 */
  const onSwitchChange = async (e: any, record: any) => {
    const res: any = await userAPI.updateUserState({
      id: record.id,
      state: e ? 0 : 1,
    });
    if (res.code === 200) {
      setPage({
        ...props.page,
      });
      message.success("编辑成功");
    }
    console.log(e);
  };
  const editData = (obj: { id: string }) => {
    props.setRecord({ ...obj });
    props.setIsModalVisible(true);
  };
  const deleteAdminUser = async (data: { id: string }) => {
    const res: any = await userAPI.deleteAdminUser(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };
  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 修改密码*/
  const onFinish = async (values: any) => {
    setLoading(true);
    const res: any = await userAPI.putresetPasswordById({
      id: recordData.id,
      password: values.password,
    });
    if (res.code === 200) {
      setLoading(false);
      message.success("修改成功");
      handleCancel();
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleCancel = () => {
    setLoading(false);
    pwdFrom.current.resetFields();
    setIsModalVisiblePwd(false);
  };
  const newPwdData = (data: any) => {
    setRecord(data);
    setIsModalVisiblePwd(true);
  };
  /* ------ */
  const columns: TableColumnsType<DataType> = [
    { title: "昵称", dataIndex: "fullName", key: "id" },
    { title: "账号", dataIndex: "username", key: "id" },
    {
      title: "角色",
      dataIndex: "regTime",
      key: "id",
      render: (_: any, record: any) => {
        return <div key={record.id}>{record.roles[0]?.name || ""}</div>;
      },
    },
    { title: "最后登录时间", dataIndex: "endTime", key: "id" },
    {
      title: "状态",
      dataIndex: "state",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            <Switch
              checkedChildren="正常"
              unCheckedChildren="禁用"
              defaultChecked={record.disable === 0}
              key={record.id}
              onChange={() => onSwitchChange(record.disable, record)}
            />
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editData(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定重置密码？"
            onConfirm={() => {
              newPwdData(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" size="small">
              重置密码
            </Button>
          </Popconfirm>
          <Popconfirm
            title="确定删除账号吗？"
            onConfirm={() => {
              deleteAdminUser(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          tableLayout="auto"
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
      <Modal
        title="重置密码"
        maskClosable={false}
        confirmLoading={loading}
        visible={isModalVisiblePwd}
        okText="确认"
        cancelText="取消"
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          ref={pwdFrom}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="horizontal"
          size="large"
        >
          <Form.Item
            label="新密码"
            name="password"
            rules={[{ required: true, message: "请输入新密码" }]}
          >
            <Input placeholder="请输入新密码" />
          </Form.Item>

          <div className="tab_but">
            <Button size="middle" onClick={handleCancel}>
              取消
            </Button>
            <Button
              type="primary"
              size="middle"
              loading={loading}
              htmlType="submit"
            >
              确定
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default TableList;
