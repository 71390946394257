import { service, BASE_URL } from "../request";
const SourcecommodityApi = {
   
    /* 获取自提点列表*/
    getAddressList(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/extract/extractAddress/list`,
            params
        });
    },
    // 删除自提点
    deleteAddressById(data: { id: string }) {
        return service({
            url: BASE_URL + `/api/admin/extract/extractAddress/${data.id}`,
            method: 'DELETE',
        });
    },
    // 添加自提点
     addAddressData(data: any) {
        return service({
            url: BASE_URL + '/api/admin/extract/extractAddress',
            method: 'POST',
            data
        });
    },
    // 根据ID批量查询自提点
    getAddressDataMore(data: any) {
        return service({
            url: BASE_URL + '/api/admin/extract/extractAddress/batchFind',
            method: 'POST',
            data
        });
    },
    // 用户源力值签到
    getSignIn(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/force/forceSign/list`,
            params
        });
    },


   
}
export default SourcecommodityApi;