import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  Table,
  TableColumnsType,
} from "antd";
import { Key } from "react";
import "./table.less";
import BrandApi from "@/api/modules/brand";
interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  showModal: Function;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;
  /* 启用禁用 */
  const onSwitchChange = async (checked: number, event: any) => {
    console.log(checked, event);
    if (checked) {
      const res: any = await BrandApi.putOnline({
        id: event.id,
      });
      if (res.code === 200) {
        message.success("启用成功");
        props.handleSuccess();
      } else {
        props.handleSuccess();
      }
    } else {
      const res: any = await BrandApi.putOffline({
        id: event.id,
      });
      if (res.code === 200) {
        message.success("取消成功");
        props.handleSuccess();
      } else {
        props.handleSuccess();
      }
    }
  };
  const deleteDictById = async (data: { id: string }) => {
    console.log(data.id);
    const res: any = await BrandApi.deleteById(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };

  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = (obj: { id: string }) => {
    props.showModal(obj);
  };
  const columns: TableColumnsType<DataType> = [
    { title: "类型名称", dataIndex: "brandName", key: "id" },
    { title: "创建时间", dataIndex: "createTime", key: "id" },

    {
      title: "状态",
      dataIndex: "state",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            <Switch
              checkedChildren="上架"
              unCheckedChildren="下架"
              defaultChecked={record.brandState === 0}
              key={record.id}
              onChange={() => {
                onSwitchChange(record.brandState, record);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editData(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定删除品牌吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;
