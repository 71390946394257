import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
} from "antd";
import { Table } from "antd";
import "./table.less";
import { Key } from "react";
// import GOODSAPI from "@/api/modules/goods";
import SourceExchange from "@/api/modules/sourceExchange";
import SourceCommodityApi from "@/api/modules/sourceCommodity";
import { useNavigate } from "react-router-dom";

interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
  authentication:Number;
}

type Props = {
  isModalVisible: boolean;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;

};
const TableList = (props: Props) => {
  const navigate = useNavigate();
  const { total, page, setPage } = props;
  
  const deleteDictById = async (data: { id: string }) => {
    console.log(data.id);
    const res: any = await SourceCommodityApi.deleteSourceCommodity(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };
  const upOrDown = async (data: { id: string, status:number}) => {
    // console.log(data.id);
    const res: any = await SourceCommodityApi.upAndDown(data);
    console.log(res);
    if (res.code === 200) {
      if(data.status===1){
        message.success("上架成功");
      }else{
        message.success("下架成功");
      }
      props.handleSuccess();
    }
  };
  

  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = async (obj: { id: string }) => {
    navigate(`/home/sourceGoods/edit?id=${obj.id}`);
  };
  const seeData = async (obj: { id: string,type:number }) => {
    obj.type=1;
    // console.log('查看数据',obj);
    
    navigate(`/home/sourceGoods/edit?id=${obj.id}&type=${obj.type}`);
  };
  /* 推荐 */
  const onSwitchReferee = async (checked: number, event: any) => {
    
    let res: any = "";
    if (checked) {
      res = await SourceExchange.ipDoll({ id: event.id,authentication:0 });
    } else {
      res = await SourceExchange.ipDoll({ id: event.id,authentication:1 });
    }
    // console.log(res);
    
    if (res.code === 200&&res.data) {
      message.success(res.msg);
      props.handleSuccess();
    }else{
      message.error('多个规格商品不可设置');
      props.handleSuccess();
     setTimeout(() => {
      window.location.reload() // 强制页面刷新
     }, 0);
    }
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: "封面图",
      dataIndex: "proCover",
      key: "id",
      render: (_: any, record: any) => (
        <div>
          <img src={record.proCover} alt="" width={50} height={50} />
        </div>
      ),
    },
    {
      title: "商品名称",
      dataIndex: "proName",
      key: "id",
    },
    {
      title: "状态", dataIndex: "status", key: "id",
      render: (_: any, record: any) => (
        <div>
          {record.status === 0 ? '上架' : "下架"}
        </div>
      ),
    },
    /*     {
          title: "卖价",
          dataIndex: "proSalePrice",
          key: "id",
        }, */
    /*     {
          title: "活动价",
          dataIndex: "proActivityPrice",
          key: "id",
        }, */
    {
      title: "已兑数量",
      dataIndex: "recordNum",
      key: "id",
    },

    {
      title: "发布时间",
      dataIndex: "createTime",
      key: "id",
    },
    {
      title: "设置认证礼品",
      dataIndex: "createTime",
      key: "id",
      render: (_: any, record: any) => {
        return (
          <div key={record.id}>
            <Switch
              defaultChecked={record.authentication === 0}
              key={record.id}
              onChange={()=>{onSwitchReferee(record.authentication,record)}}
            />
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          {record.status === 0 ? <Button
            size="small"
           onClick={()=>{
            upOrDown(record)
           }}
          >
            下架
          </Button> : <Button
            size="small"
            onClick={()=>{
              upOrDown(record)
             }}
          >
            上架
          </Button>}
          <Button
            size="small"
            onClick={() => {
              seeData(record);
            }}
          >
            查看
          </Button>
          <Button
            size="small"
            onClick={() => {
              editData(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定删除商品吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
