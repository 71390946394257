import { service, BASE_URL } from "../request";
const selfLiftingAPI = {
   
    /* 获取自提点列表*/
    getSourceCommodity(params: any) {
        params.status=params.onlineState;
        delete params.onlineState;
        return service({
            url: `${BASE_URL}/api/admin/force/forceProduct/list`,
            params
        });
    },
    // 删除商品
    deleteSourceCommodity(data: { id: string }) {
        return service({
            url: BASE_URL + `/api/admin/force/forceProduct/${data.id}`,
            method: 'DELETE',
        });
    },
    // 添加源力商品
     addSourceCommodity(data: any) {
        return service({
            url: BASE_URL + '/api/admin/force/forceProduct',
            method: 'POST',
            data
        });
    },
    // 上下架商品
    upAndDown(data: { id: string }) {
        return service({
            url: BASE_URL + `/api/admin/force/forceProduct/shelves/${data.id}`,
            method: 'PUT',
        });
    },
     /* 根据ID查询商品*/
     getSourceCommodityById(params: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/force/forceProduct/${params.id}`,
            params
        });
    },
    // 根据ID编辑
    updeatDataById(data: { id: string }) {
        return service({
            url: BASE_URL + `/api/admin/force/forceProduct/${data.id}`,
            method: 'PUT',
            data
        });
    },
    
   
    


   
}
export default selfLiftingAPI;