import { Button, Form, Input, message, Modal } from "antd";
import { useRef, useState, useEffect } from "react";
import SourceExchange from "@/api/modules/sourceExchange";
// import SelfLiftingAPI from "@/api/modules/selfLifting";

type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
  handleSuccess: any;
  getListData:any;
};

const Popup = (props: Props) => {
  let [loading, setLoading] = useState<boolean>(false);
  const addFrom: any = useRef();
  useEffect(() => {
    if (props.record.id) {
      setTimeout(() => {
        addFrom.current.setFieldsValue({
          ...props.record,
        });
      }, 0);
     
    }
  }, [props.record]);

  /* 表单提交  同时获取逆地址编码*/
  const onFinish = async (values: any) => {
    // console.log('好消息', values);
    let obj = {
      ...values,
    };
    setLoading(true);
    const req: any = await SourceExchange.exchangeCode(obj);

    if (req.data) {
      removeForm();
      props.handleCancel();
      setDataList({ ...req.data })
      showModal2();
    } else {
      removeForm();
      // props.handleCancel();
      message.error("兑换码错误");
    }

    setLoading(false);
  };

  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('好消息', errorInfo);
  };
  const showModal2 = () => {
    setIsModalOpen(true);
  };

  const handleOk2 = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen(false);
  };
  const exchange = async (id:any,state:any) => {
    const req: any = await SourceExchange.updateSourceById({id,state});
   if(req.code===200){
    handleCancel2();
    message.success("兑换成功");
    props.getListData();
   }
  };
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataList, setDataList] = useState<any>('');

  return (
    <div>
      <Modal
        title={"兑换商品"}
        maskClosable={false}
        width={600}
        confirmLoading={loading}
        visible={props.isModalVisible}
        onOk={props.handleSuccess}
        onCancel={handleCancel}
        footer={false}
        okText="确认"
        cancelText="取消"
      >
        <Form
          ref={addFrom}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="horizontal"
          size="large"
        >
          <Form.Item
            name="code"
            label='请输入兑换码：'
            labelCol={{ span: 5 }}
            rules={[{ required: true, message: "请输入兑换码" }]}

          >
            <Input placeholder="请输入兑换码" />
          </Form.Item>


          <div className="tab_but">
            <Button size="middle" onClick={handleCancel}>
              取消
            </Button>
            {/* <button onClick={}>getListData</button> */}
            <Button
              type="primary"
              size="middle"
              loading={loading}
              htmlType="submit"

            >
              查看
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal title="兑换商品" footer={null} visible={isModalOpen} onOk={handleOk2} onCancel={handleCancel2}>
        <div style={{ marginLeft: '110px', marginBottom: '10px' }}>
          <div>用户昵称:&nbsp;&nbsp;{dataList.userName}</div>
          <br />
          <div>联系电话:&nbsp;&nbsp;{dataList.userPhone}</div>
          <br />
          <div style={{ display: 'flex' }}>
            兑换商品:&nbsp;&nbsp;
            <div>
              <div> {dataList.commodityName}</div>
              <div>
                <img style={{ width: '100px', height: '100px' }} src={dataList.proPicture} alt="" />
              </div>
            </div>

          </div>
          <br />
          <div>商品价格:&nbsp;&nbsp;{dataList.price}</div>
          <br />
          <div>数量:&nbsp;&nbsp;{dataList.num}</div>
          <br />
          <div>兑换方式:&nbsp;&nbsp;{dataList.type===0?'自提':'邮寄'}</div>
          <br />
          <div>地址:&nbsp;&nbsp;{dataList.userAddress}</div>
          <br />
          <div>兑换时间:&nbsp;&nbsp;{dataList.exchangeTime}</div>
          <br />
          <div>状态:&nbsp;&nbsp;{dataList.state===0?'已兑换':'未兑换'}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '350px', marginLeft: "60px" }}>
          <Button onClick={()=>{handleCancel2()}} style={{ width: '150px' }} type="primary">取消兑换</Button>

          <Button onClick={()=>{exchange(dataList.id,0)}} style={{ width: '150px' }}>确认兑换</Button></div>

      </Modal>
    </div>

  );
};

export default Popup;
