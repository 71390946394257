import axios from 'axios';
import { message } from "antd";
import { BASE_URL, TIMEOUT, mapBaseURL } from "./config";
const service = axios.create({
  /*   baseURL: BASE_URL, */
  timeout: TIMEOUT
});
const mapService = axios.create({
  /*   baseURL: BASE_URL, */
  timeout: TIMEOUT
});
// http request 拦截器
mapService.interceptors.request.use(config => {
  const TOKEN = sessionStorage.getItem('token') || ""
  // 发送网络请求时, 在界面的中间位置显示Loading的组件,使用ant-design插件，这里不再赘述
  //请求携带的信息
  config.headers = {
    'Content-Type': 'application/json',
    "TOKEN": TOKEN,
    ...config.headers,
  };
  return config;
}, err => {
  //...关闭加载loading的组件，显示消息提示弹窗
  return err;
});

// http response 拦截器
mapService.interceptors.response.use(res => {
  if (res.data.status === 0) {
    return res.data;
  } else {
    message.error("腾讯地图api调用错误网络超时");
    return res.data;
  }
}, err => {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        console.log("请求错误");
        break;
      case 401:
        console.log("未授权访问");

        break;
      case 501:
        console.log("未授权访问");
        break;
      default:
        console.log("其他错误信息");
    }
  }
  return err;
});

// http request 拦截器
service.interceptors.request.use(config => {
  const TOKEN = sessionStorage.getItem('token') || ""
  // 发送网络请求时, 在界面的中间位置显示Loading的组件,使用ant-design插件，这里不再赘述
  //请求携带的信息
  config.headers = {
    'Content-Type': 'application/json',
    "TOKEN": TOKEN,
    ...config.headers,
  };
  return config;
}, err => {
  //...关闭加载loading的组件，显示消息提示弹窗
  return err;
});

// http response 拦截器
service.interceptors.response.use(res => {
  if (res.headers.token) {
    sessionStorage.setItem('token', res.headers.token)
  }
  if (res.data.code === 200) {
    return res.data;
  } else if (res.data.code === 10001) {
    message.error(res.data.msg);
    setInterval(() => {
      window.location.href = "/"
    }, 500)

    return res.data;
  } else if (res.data.code === 10005) {
    message.error(res.data.msg);
    setInterval(() => {
      window.location.href = "/"
    }, 500)
  } else {
    message.error(res.data.msg);
    return res.data;
  }
}, err => {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        console.log("请求错误");
        break;
      case 401:
        console.log("未授权访问");
        window.location.href = "/error"
        break;
      case 501:
        message.error("网络超时");
        break;
      case 504:
        message.error("服务器错误");
        break;
      default:
        console.log("其他错误信息");
    }
  }
  return err;
});
export {
  service,
  BASE_URL,
  mapBaseURL,
  mapService

}
  ;