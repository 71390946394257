import { service, BASE_URL } from "../request";
import { ListData } from "@/utils/tsModules/goods";
const GOODSAPI = {
    /* 获取列表 */
    getList(params: ListData) {
        return service({
            url: `${BASE_URL}/api/admin/product/product/list`,
            params
        });
    },
    /* 上架 */
    putDataOnline(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/product/online/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 下架 */
    putDataOffline(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/product/offline/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 推荐 */
    putDataOnReferee(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/product/onReferee/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 不推荐 */
    putDataOffReferee(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/product/offReferee/${params.id}`,
            params,
            method: 'PUT'
        });
    },
    /* 删除 */
    deleteDataById(params: { id: string, }) {
        return service({
            url: `${BASE_URL}/api/admin/product/product/${params.id}`,
            params,
            method: 'DELETE'
        });
    },
    /* 添加分类 */
    /*   addClassData(data: any) {
          return service({
              url: `${BASE_URL}/api/admin/product/sort`,
              data,
              method: 'POST'
          });
      }, */
    /* 编辑分类 */
    /*    updeatClassById(data: any) {
           return service({
               url: `${BASE_URL}/api/admin/product/sort/${data.id}`,
               data,
               method: 'PUT'
           });
       }, */
    /* 新增商品 */
    addGoodsData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/product/product`,
            data,
            method: 'POST'
        });
    },
    /*  根据id查询*/
    getByIdData(params: any) {
        return service({
            url: `${BASE_URL}/api/admin/product/product/${params.id}`,
        });
    },
    /*编辑 */
    updeatDataById(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/product/product/${data.id}`,
            data,
            method: 'PUT'
        });
    },
}
export default GOODSAPI;