import GoodsApi from "@/api/modules/class";
import { Button, Input, Select, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import Popup from "./components/popup";
import TableList from "./components/Table";
import "./index.less";
type Props = {};
const Goodsclass = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>("");
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const conponNameRef = useRef<any>();
  const [page, setListPage] = useState<any>({
    current: 1,
    size: 10,
    sortName: "",
    sortState: "",
  });
  const { Option } = Select;
  const [conponState, setConponState] = useState<number>(0);
  useEffect(() => {
    getListData();
  }, [page]);

  const getListData = async () => {
    setLoading(true);
    const res: any = await GoodsApi.getClassList(page);
    console.log(res, "分类的列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: Object) => {
    setRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setRecord("");
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setRecord("");
    setIsModalVisible(false);
    getListData();
  };
  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      sortName: conponNameRef.current.input.value || "",
      sortState: conponState||conponState===0?conponState:''
    };
    console.log(obj);

    setListPage({ ...obj });
  };

  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };

  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="分类名称" ref={conponNameRef} allowClear />
        <Select placeholder="状态" onChange={changeConponState} allowClear>
          <Option value={0}>启用</Option>
          <Option value={1}>禁用</Option>
        </Select>
        <Button onClick={searchInPut} size="middle">
          查询
        </Button>
        <Button
          type="primary"
          onClick={() => {
            showModal("");
          }}
          size="middle"
        >
          新增
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          showModal={showModal}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
        ></TableList>
      }
      <Popup
        isModalVisible={isModalVisible}
        record={record}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
        listData={listData}
      ></Popup>
    </div>
  );
};

export default Goodsclass;
