import GoodsOrderApi from "@/api/modules/goodsOrder";
import { Button, Image, Spin, Table, TableColumnsType } from "antd";
import { Key } from "react";
import "./table.less";
type Props = {
  isModalVisible: boolean;
  showModal: Function;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = (obj: { id: string }) => {
    props.showModal({ ...obj, look: false });
  };
  const editLookData = (obj: { id: string }, look: boolean) => {
    /*     console.log(obj);
    const res: any = await GoodsOrderApi.getOrderByIdInfo(obj);
    console.log(res, "resss");
 */ console.log(obj);
    props.showModal({ ...obj, look });
  };
  /* 	integer($int32)
0-待商家处理 1-待买家发货
 2-待商家收货 3-待商家发货 4-待买家收货
  5-待商家同意退款 6-待平台审核 7-退款处理中
   8-售后完成 9-取消 10-商家驳回 
   11-平台驳回 12 退款失败 */
  const stateText = (t: any) => {
    switch (t) {

      case 7:
        return "售后处理中";
      case 8:
        return "售后完成";
      default:
        break;
    }
  };
  const columns: TableColumnsType<any> = [
    { title: "创建时间", dataIndex: "createTime", key: "id" },
    { title: "订单号", dataIndex: "commodityOrderNo", key: "id" },

    { title: "退款金额", dataIndex: "refundFee", key: "id" },

    /* { title: "申请原因", dataIndex: "reason", key: "id" }, */

    {
      title: "申请用户",
      dataIndex: "userName",
      key: "id",
    },
    {
      title: "退款结果",
      dataIndex: "conponType",
      key: "id",
      render: (_: any, record: any) => <span>{record.refundResult=="7"?"同意":record.refundResult=="10"?'驳回':''}</span>,
    },
    {
      title: "状态",
      dataIndex: "conponType",
      key: "id",
      render: (_: any, record: any) => <span>{stateText(record.state)}</span>,
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editLookData(record, true);
            }}
          >
            详情
          </Button>
          {record.state === 7 ? (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                editLookData(record, false);
              }}
            >
              审核
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;
