import { Button, Form, Input, message, Modal, Select, TreeSelect } from "antd";
import SystemApi from "@/api/modules/system";
import React, { useRef, useState, useEffect } from "react";
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
};
const Popup = (props: Props) => {
  const { Option } = Select;
  let [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("0");
  const [conponState, setConponState] = useState<number | string>("");
  const addFrom: any = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.record.id) {
      // setValue(props.record.pid);
      // addFrom.current.setFieldsValue({
      //   ...props.record,
      // });
       setTimeout(() => {
        addFrom.current.setFieldsValue({
          ...props.record,
        });
      }, 0);
    } /* else if(!props.record){
      addFrom.current.resetFields()
    } */
  }, [props.record]);

  const handleOk = () => {
    props.handleCancel();
  };
  const onChange = (newValue: string) => {
    setValue(newValue);
  };
  const onFinish = async (values: any) => {
    values.terminal=2
    values.sort = values.sort - 0;
    setLoading(true);
    if (props.record.id) {
      updateDictData({ id: props.record.id, ...values });
    } else {
      const res: any = await SystemApi.addMenuData(values);
      if (res.code === 200) {
      
        message.success("添加成功");
        addFrom.current.resetFields();
        handleOk();
      }
    }
    setLoading(false);
  };
  const updateDictData = async (data: any) => {
    const res: any = await SystemApi.putMenuData(data);
    if (res.code === 200) {
      setLoading(false);
      message.success("编辑成功");
      addFrom.current.resetFields();
      handleOk();
    }
  };
  const handleCancel = () => {
    addFrom.current.resetFields();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  /* 存储菜单目录级别 */
  const changeConponState = (e: any) => {
    setConponState(e - 0);
  };
  return (
    <Modal
      title={props.record.id ? "编辑菜单" : "新增菜单"}
      maskClosable={false}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="确认"
      cancelText="取消"
      footer={null}
    >
      <Form
        ref={addFrom}
        initialValues={{
          pid: "0",
        }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        {" "}
        <Form.Item
          label="菜单名称"
          name="name"
          rules={[{ required: true, message: "请输入菜单名称" }]}
        >
          <Input placeholder="请输入菜单名称" />
        </Form.Item>
        <Form.Item
          label="菜单路由"
          name="url"
          rules={[{ required: true, message: "请输入菜单路由" }]}
        >
          <Input placeholder="请输入菜单路由" />
        </Form.Item>
        <Form.Item label="父级菜单" name="pid">
          <TreeSelect
            placement="bottomLeft"
            style={{ width: "100%" }}
            value={value}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="选择父级菜单"
            onChange={onChange}
            treeData={props.listData}
            treeDefaultExpandedKeys={[value]}
            fieldNames={{ label: "name", value: "id", children: "menus" }}
          ></TreeSelect>
        </Form.Item>
        <Form.Item
          label="排序"
          name="sort"
          rules={[{ required: true, message: "请输入菜单排序" }]}
        >
          <Input placeholder="请输入菜单排序" />
        </Form.Item>
        <Form.Item
          label="图标"
          name="icon"
          rules={[{ required: true, message: "请输入图标" }]}
        >
          <Input placeholder="请输入菜单图标" />
        </Form.Item>
        <Form.Item
          label="目录级别"
          name="root"
          rules={[{ required: true, message: "请选择目录级别" }]}
        >
          <Select
            placeholder="目录级别"
            onChange={changeConponState}
            allowClear
          >
            <Option value={1}>系统</Option>
            <Option value={2}>菜单</Option>
            <Option value={3}>页面</Option>
          </Select>
        </Form.Item>
        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>{" "}
    </Modal>
  );
};

export default Popup;
