
import './App.less';
import { Route, Routes, HashRouter } from "react-router-dom"
import Login from './views/login';
import Home from './views/home';
import ErrorDom from './views/error';
function App() {

  return (
    <HashRouter>
      {/* Switch组件相当于程序switch 默认匹配一个 */}
      <Routes >
        {/* 映射关系，将路径和UI组件映射在一起 */}
        <Route path="/" element={<Login />}></Route>
        <Route path="/home/*" element={<Home />}></Route>
        <Route path="*" element={<ErrorDom />}></Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
