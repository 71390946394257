import { BASE_URL } from "@/api/config";
import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import "@wangeditor/editor/dist/css/style.css";
import React, { useEffect, useState } from "react";
type Props = {
  html: any;
  setHtml: Function;
};
function MyEditor(props: Props) {
  /* const [editor, setEditor] = useState<any>(null); */ // 存储 editor 实例

  const [editor, setEditor] = useState<any>(null);
  // 模拟 ajax 请求，异步设置 html
  /*     useEffect(() => {
        setTimeout(() => {
            setHtml('<p>hello&nbsp;<strong>world</strong>.</p>\n<p><br></p>')
        }, 1500)
    }, []) */
  type InsertFnType = (data: string, alt: string, href: string) => void;
  const toolbarConfig = {};
  const editorConfig = {
    placeholder: "请输入内容...",
    MENU_CONF: {
      uploadImage: {
        server:
          BASE_URL + "/api/open/aliOssFile/upload?filePath=image/&resType=1",
        fieldName: "file",
        meta: {
          token: sessionStorage.getItem("token") || "",
        },
        customInsert(res: any, insertFn: InsertFnType) {
          // TS 语法
          // customInsert(res, insertFn) {                  // JS 语法
          // res 即服务端的返回结果

          // 从 res 中找到 url alt href ，然后插图图片
          insertFn(res.data, res.data, res.data);
        },
      },
      uploadVideo: {
        server:
          BASE_URL + "/api/open/aliOssFile/upload?filePath=video/&resType=1",
        fieldName: "file",
        meta: {
          token: sessionStorage.getItem("token") || "",
        },
        customInsert(res: any, insertFn: InsertFnType) {
          // TS 语法
          // customInsert(res, insertFn) {                  // JS 语法
          // res 即服务端的返回结果

          // 从 res 中找到 url alt href ，然后插图图片
          insertFn(res.data, res.data, res.data);
        },
      },
    },
  };
  // 及时销毁 editor ，重要！
  useEffect(() => {
    return () => {
      if (editor == null) return;
      editor.destroy();
      props.setHtml("");
    };
  }, [editor]);
  return (
    <>
      <div style={{ border: "1px solid #ccc", zIndex: 100, marginTop: "15px" }}>
        <Toolbar
         
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: "1px solid #ccc" }}
        />
        <Editor
          defaultConfig={editorConfig}
          value={props.html}
          onCreated={setEditor}
          onChange={(editor) => props.setHtml(editor.getHtml())}
          mode="default"
          style={{ minHeight: "300px" }}
        />
      </div>
    </>
  );
}

export default MyEditor;
