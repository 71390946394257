import { Button, Form, Input, message, Modal, Select } from "antd";
import SystemApi from "@/api/modules/system";
import userAPI from "@/api/modules/user";
import React, { useRef, useState, useEffect } from "react";
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any;
  listData: any;
  handleSuccess: Function;
};
const Popup = (props: Props) => {
  const { Option } = Select;
  let [loading, setLoading] = useState<boolean>(false);
  const [conponState, setConponState] = useState<number | string>("");
  const [roleList, setRoleList] = useState<Array<any>>([]);
  const addFrom: any = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.record.id) {
        setTimeout(() => {
        addFrom.current.setFieldsValue({
          ...props.record,
          roleIds: props.record?.roles[0].id
        });
      }, 0);
      // addFrom.current.setFieldsValue({
      //   ...props.record,
      //   roleIds: props.record?.roles[0].id,
      // });
    }
    console.log(props.record);
  }, [props.record]);

  useEffect(() => {
    getRoleListData("");
  }, []);
  /* 获取角色列表 */
  const getRoleListData = async (name: string | "") => {
    const res: any = await SystemApi.getRoleList({
      current: 1,
      size: 999,
      name,
    });
    if (res.code === 200) {
      setRoleList([...res.data.records]);
    }
    console.log(res, "角色列表");
  };

  const handleOk = () => {
    props.handleSuccess();
  };
  const onFinish = async (values: any) => {
    console.log(conponState);

    console.log(values);
    values.roleIds = [values.roleIds];
    setLoading(true);
    if (props.record.id) {
      updateDictData({ ...props.record, ...values });
    } else {
      const res: any = await userAPI.addAdminUser(values);
      if (res.code === 200) {
      
        message.success("添加成功");
        addFrom.current.resetFields();
        handleOk();
      }
    }
    setLoading(false);
  };
  const updateDictData = async (data: any) => {
    const res: any = await userAPI.putAdminUserById(data);
    if (res.code === 200) {
      setLoading(false);
      message.success("编辑成功");
      addFrom.current.resetFields();
      handleOk();
    }
  };
  const handleCancel = () => {
    addFrom.current.resetFields();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  /* 存储菜单目录级别 */
  const changeConponState = (e: string) => {
    setConponState(e);
  };
  return (
    <Modal
      title={props.record.id ? "编辑账号" : "新增账号"}
      maskClosable={false}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="确认"
      cancelText="取消"
      footer={null}
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        <Form.Item
          label="昵称"
          name="fullName"
          rules={[{ required: true, message: "请输入昵称" }]}
        >
          <Input placeholder="请输入昵称" />
        </Form.Item>
        <Form.Item
          label="账号"
          name="username"
          rules={[{ required: true, message: "请输入账号" }]}
        >
          <Input placeholder="请输入账号" />
        </Form.Item>
        {props.record.id ? null : (
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input placeholder="请输入密码" />
          </Form.Item>
        )}
        <Form.Item
          label="角色"
          name="roleIds"
          rules={[{ required: true, message: "请选择用户关联角色" }]}
        >
          <Select
            placeholder="选择用户关联角色"
            onChange={changeConponState}
            allowClear
          >
            (
            {roleList.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
            )
          </Select>
        </Form.Item>
        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Popup;
