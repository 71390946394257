import {
  UserOutlined,
} from "@ant-design/icons";
import "./index.less";
import { Dropdown, MenuProps } from "antd";
import { Breadcrumb, Layout, Menu, Avatar } from "antd";
import React, { useState, useEffect } from "react";
import logo from "../../assets/images/avatar.png";
import logoI from "@/assets/images/favicon.png";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
/* 引入路由 */
import SignIn from "../signIn"
import SourceExchange from "../sourceExchange"
import SourceMall from "../sourceMall"
import SourceRate from "../sourceRate"
import SourceGoodsEdit from "../sourceMall/sourceGoodsEdit";
import Business from "../business";
import Coupon from "../coupon";
import Order from "../order";
import WriteOff from "../writeoff";
import Dictionaries from "../dictionaries";
import ErrorDom from "../error";
import Banner from "../banner";
import BannerEdit from "../banner/BannerEdit";
import Settings from "../settings";
import User from "../user";
import GoodsList from "../goods";
import Withdrawal from "../withdrawal";
import Goodsclass from "../goodsclass";
import GoodsBrand from "../brand";
import GoodsEdit from "../goods/goodsEdit";
import Examine from "../examine";
import GoodsOrder from "../gooodsOrder";
import Role from "../role";
import MenuAdmin from "../menuAdmin";
import SelfLifting from "../selfLifting"
import WxUser from "../wxuser";
import SystemApi from "@/api/modules/system";
import Aftersales from "../gooodsaftersales";

import Icon from "@/utils/icon/icon";
const { Header, Content, Sider } = Layout;
type Props = {};
type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const Home = (props: Props) => {
  const logoAuth = () => {
    sessionStorage.clear();
    navigate("/");
  };
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a target="_blank" rel="noopener noreferrer" onClick={logoAuth}>
              退出
            </a>
          ),
        },
      ]}
    />
  );
  const [items, setItems] = useState<any>([]);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const onSelect = (e: any) => {
    setUrl(e.key);
    navigate(e.key);
  };

  const [url, setUrl] = useState("");
  // 通过useEffect函数来实现componentDidMount
  useEffect(() => {
    routerName();
  }, [location.pathname]);
  useEffect(() => {
    getTokenMenuTree();
  }, []);
  const routerName = () => {
    console.log(location.pathname);
    setUrl(location.pathname);
  };
  /*根据token 获取菜单树  */
  const getTokenMenuTree = async () => {
    const res: any = await SystemApi.getTokenMenuTree({});
    if (res.code === 200) {
      let arr = res.data[0]?.menus;
      const menuData = menuDataTree(arr);
      console.log(menuData);
      setItems([...menuData])
    }
  };
  /* 递归菜单 */
  const menuDataTree = (menu: any = []) => {
    return menu.map((item: any,index:number) => {
      if (item.menus) {
        return ( getItem(item.name, item.url, <Icon icon={item.icon} />, 
          menuDataTree(item.menus)
        ));
      } else {
        return (getItem(item.name,item.url, <Icon icon={item.icon} />))
      }
    });
  };

  return (
    <div>
      <Header className="header_box" style={{ padding: 0 }}>
        <div className="header_box_left">
          <img src={logoI} width="80" alt="" />
        </div>

        <div className="header_box_right">
          <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
              <div className="flex header_box_right_box">
                <div>超级管理员</div>
                <Avatar
                  src={logo}
                  size={{ xs: 24, sm: 32, md: 40, lg: 40, xl: 40, xxl: 40 }}
                  icon={<UserOutlined />}
                />
              </div>
            </a>
          </Dropdown>
        </div>
      </Header>
      <Layout style={{ minHeight: "91vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            className="none_selet"
            selectedKeys={[url]}
            mode="inline"
            onSelect={(e) => {
              onSelect(e);
            }}
            items={items}
          />
        </Sider>
        <Layout className="site-layout">
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              {/*    <Breadcrumb.Item>User</Breadcrumb.Item>
                            <Breadcrumb.Item>Bill</Breadcrumb.Item> */}
            </Breadcrumb>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 550 }}
            >
              <Routes>
                {/* 映射关系，将路径和UI组件映射在一起 */}
                <Route
                  path="/"
                  element={<Navigate to="/home/business" />}
                ></Route>
                <Route path="signIn" element={<SignIn />}></Route>
                <Route path="withdrawal" element={<Withdrawal />}></Route>
                <Route path="sourceGoods/edit" element={<SourceGoodsEdit />}></Route>
                <Route path="sourceExchange" element={<SourceExchange />}></Route>
                <Route path="sourceMall" element={<SourceMall />}></Route>
                <Route path="sourceRate" element={<SourceRate />}></Route>
                <Route path="business" element={<Business />}></Route>
                <Route path="selflifting" element={<SelfLifting />}></Route>
                <Route path="coupon" element={<Coupon />}></Route>
                <Route path="order" element={<Order />}></Route>
                <Route path="writeOff" element={<WriteOff />}></Route>
                <Route path="dictionaries" element={<Dictionaries />}></Route>
                <Route path="banner" element={<Banner />}></Route>
                <Route path="banner/edit" element={<BannerEdit />}></Route>
                <Route path="settings" element={<Settings />}></Route>
                <Route path="user" element={<User />}></Route>
                <Route path="goodsList" element={<GoodsList />}></Route>
                <Route path="GoodsBrand" element={<GoodsBrand />}></Route>
                <Route path="goodsClass" element={<Goodsclass />}></Route>
                <Route path="goodsEdit" element={<GoodsEdit />}></Route>
                <Route path="examine" element={<Examine />}></Route>
                <Route path="goodsOrder" element={<GoodsOrder />}></Route>
                <Route path="role" element={<Role />}></Route>
                <Route path="menuAdmin" element={<MenuAdmin />}></Route>
                <Route path="wxUser" element={<WxUser />}></Route>
                <Route path="aftersales" element={<Aftersales />}></Route>
                <Route path="*" element={<ErrorDom />}></Route>
              </Routes>
            </div>
          </Content>
          {/*  <Footer style={{ textAlign: 'center' }}>商家联盟</Footer> */}
        </Layout>
      </Layout>
    </div>
  );
};

export default Home;
