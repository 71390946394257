import BrandApi from "@/api/modules/brand";
import { Button, Form, Input, message, Modal, Select, Switch } from "antd";
import { log } from "console";
import { useEffect, useRef, useState } from "react";
const { Option } = Select;
type Props = {
  isModalVisible: boolean;
  handleCancel: any;
  record: any | "";
  handleSuccess: any;
  setSublistData: Function;
  sublistData: any;
};

const Popup = (props: Props) => {
  let [loading, setLoading] = useState<boolean>(false);
  const [brandValue, setBrandValue] = useState<string>('是');
  const addFrom: any = useRef();
  useEffect(() => {
    if (props.record) {
      // addFrom.current.setFieldsValue({
      //   ...props.record,
      // });
      setTimeout(() => {
        addFrom.current.setFieldsValue({
          ...props.record,
        });
      }, 0);
    }
  }, [props.record]);

  /* 表单提交 */
  const onFinish = async (values: any) => {
    console.log('传下来的数据',values);
    // console.log(values);
    values.status = 0;
    values.parcelState = 0;
    values.parcel = 0;
    
    if (props.record) {
      props.record.proForce -= 0;
      props.record.proStore -= 0;
      if (props.record.postage) {
        props.record.postage -= 0;
      }

      props.record.parcel -= 0;
      props.record.recordNum -= 0;
      props.sublistData.splice(props.record.index, 1, values);
      props.setSublistData([...props.sublistData]);
    } else {
      const fala: boolean = props.sublistData.some(
        (item: { proName: any }) => {
          return item.proName === values.proName;
        }
      );
      // console.log('好兄弟',props.sublistData);

      if (fala) {
        return message.error("规格已存在");
      }

      props.setSublistData([...props.sublistData, values]);
    }
    removeForm();
    props.handleCancel();
  };

  /* 清空表单数据 */
  const removeForm = () => {
    addFrom.current.resetFields();
  };
  /* 表单关闭 */
  const handleCancel = () => {
    removeForm();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  /* 存储是否包邮 */
  const handleChangeSeletBrand = (newValue: string) => {
    console.log(newValue);

    setBrandValue(newValue);
  };
  const Specifications = [
    {
      name: "是",
      id: "0",
    },
    { name: "否", id: "1" },
  ];
  const optionsC = Specifications.map(
    (item: { id: React.Key | null | undefined; name: string }) => (
      <Option key={item.id}>{item.name}</Option>
    )
  );
  return (
    <Modal
      title={props.record ? "编辑规格" : "新增规格"}
      maskClosable={false}
      width={600}
      confirmLoading={loading}
      visible={props.isModalVisible}
      onOk={props.handleSuccess}
      onCancel={handleCancel}
      footer={false}
      okText="确认"
      cancelText="取消"
    >
      <Form
        ref={addFrom}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        size="large"
      >
        <Form.Item
          label="商品名称"
          name="proName"
          rules={[{ required: true, message: "请输入商品名称" }]}
        >
          <Input placeholder="请输入商品名称" />
        </Form.Item>
        <Form.Item
          label="价格"
          name="proForce"
          rules={[{ required: true, message: "请输入价格" }]}
        >
          <Input type="number" placeholder="请输入价格" />
        </Form.Item>
        <Form.Item
          label="库存"
          name="proStore"
          rules={[{ required: true, message: "请输入库存" }]}
        >
          <Input type="number" placeholder="请输入库存" />
        </Form.Item>
        <Form.Item
          label="已兑换"
          name="recordNum"
          rules={[{ required: true, message: "请输入已兑换" }]}
        >
          <Input type="number" placeholder="请输入已兑换" />
        </Form.Item>
        {/* <Form.Item
          label="状态"
          name="status"
         
        >
           <Switch
              checkedChildren="上架"
              unCheckedChildren="下架"
              disabled
              checked={props.record.state === 1}
             
            />
        </Form.Item> */}
        <Form.Item
          label="是否包邮"
          name="parcel"
          // rules={[{ required: true, message: "请选择" }]}
        >
          <Input disabled  placeholder="是"  />
          {/* <Select
            disabled
            value={brandValue}
            placeholder="请选择"
            defaultActiveFirstOption={true}
            showArrow={false}
            filterOption={false}
            onChange={handleChangeSeletBrand}
            notFoundContent={null}
          >
            {optionsC}
          </Select> */}
        </Form.Item>
        {brandValue === "1" ? (
          <Form.Item
            label="邮费"
            name="postage"
            rules={[{ required: true, message: "请输入邮费" }]}
          >
            <Input type="number" placeholder="请输入邮费" />
          </Form.Item>
        ) : null}


        <div className="tab_but">
          <Button size="middle" onClick={handleCancel}>
            取消
          </Button>
          <Button
            type="primary"
            size="middle"
            loading={loading}
            htmlType="submit"
          >
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Popup;
