import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
  Image,
} from "antd";
import { Table } from "antd";
import "./table.less";
import React, { Key, useEffect, useState } from "react";
import CouponAPI from "@/api/modules/coupon";
interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  showModal: Function;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
  applicableDict: any;
  couponsDict: any;
};
const TableList = (props: Props) => {
  const { total, page, setPage } = props;
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = (obj: { id: string }) => {
    props.showModal({ ...obj, look: false });
  };
  const editLookData = (obj: { id: string }) => {
    console.log(obj);
    
    props.showModal({ ...obj, look: true });
  };
  const columns: TableColumnsType<DataType> = [
    { title: "用户昵称", dataIndex: "nickName", key: "id" },
    { title: "联系电话", dataIndex: "stuPhone", key: "id" },
    { title: "姓名", dataIndex: "stuName", key: "id" },
    { title: "学校名称", dataIndex: "school", key: "id" },
    {
      title: "证件",
      dataIndex: "school",
      key: "id",
      render: (_: any, record: any) => (
        <Image width={100} height={100} src={record.stuPic} />
      ),
    },
    { title: "申请时间", dataIndex: "submitTime", key: "id" },
    {
      title: "状态",
      dataIndex: "conponType",
      key: "id",
      render: (_: any, record: any) => (
        <span>
          {record.stuState === 0
            ? "未审核"
            : record.stuState === 1
            ? "通过"
            : "未通过"}
        </span>
      ),
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editLookData(record);
            }}
          >
            详情
          </Button>
          {record.stuState === 1 || record.stuState === 2 ? null :
          <Button
            type="primary"
            size="small"
            onClick={() => {
              editData(record);
            }}
          >
            审核
          </Button>
           }
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableList;
