import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
} from "antd";
import { Table } from "antd";
import "./table.less";
import { Key } from "react";
import GOODSAPI from "@/api/modules/goods";
import { useNavigate } from "react-router-dom";

interface DataType {
  normsName: Key;
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  setSublistData: Function;
  sublistData: any;
  handleSuccess: any;
  page: any;
  setPage: Function;
  setRecord: Function;
  setIsModalVisible: Function;
};
const TableList = (props: Props) => {
  const navigate = useNavigate();
  const { page, setPage } = props;
  const onSwitchChange = (index: any, data: any) => {
    if (data.state) {
      props.sublistData[index].state = 0;
    } else {
      props.sublistData[index].state = 1;
    }
    props.setSublistData([...props.sublistData]);
    console.log(props.sublistData);
  };
  const deleteDictById = (data: any, index: any) => {
    props.sublistData.splice(index, 1);
    props.setSublistData([...props.sublistData]);
    message.success("删除成功");
  };

  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = async (index: any, obj: any) => {
    console.log(index);

    props.setRecord({ index, ...obj });
    props.setIsModalVisible(true);
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: "规格名称",
      dataIndex: "normsName",
      key: "normsName",
    },
    { title: "售价", dataIndex: "sellingPrice", key: "normsName" },
    { title: "原价", dataIndex: "costPrice", key: "normsName" },
    {
      title: "库存",
      dataIndex: "stock",
      key: "normsName",
    },
    {
      title: "是否包邮",
      dataIndex: "parcelState",
      key: "normsName",
      render: (_: any, record: any) => {
        return (
          <div key={record.state}>
            {record.parcelState === 0 ? "是" : record.postage}
          </div>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "normsName",
      render: (_: any, record: any, index: any) => {
        return (
          <div key={record.state}>
            <Switch
              checkedChildren="上架"
              unCheckedChildren="下架"
              defaultChecked={record.state === 0}
              key={record.normsName}
              onChange={() => {
                onSwitchChange(index, record);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "normsName",
      render: (_: any, record: any, index) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={() => {
              editData(index, record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定删除规格吗？"
            onConfirm={() => {
              deleteDictById(record, index);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={false} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.normsName}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.sublistData}
          pagination={
            props.sublistData.length > 10
              ? {
                  position: ["bottomRight"],
                  pageSize: page.size,
                  current: page.current,
                  total: props.sublistData.length,
                  onChange: pageonChange,
                }
              : false
          }
        />
      </Spin>
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
