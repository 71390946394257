import {service,BASE_URL} from "../request";
type Props = {
    code: string,
    desc?: string,
    disable: number,
    name: string,
    pid: string,
    sort: number,
    id?: string,
}
type Update = {
    state: number,
    id: string
}
type GetList={
    code?:any|'',
}
const Dict = {
    /* 添加字典 */
    addDictionary(data: Props) {
        return service({
            url: BASE_URL+'/api/admin/dictionary',
            method: "POST",
            data
        });
    },
    /* 获取字典表 */
    getDictionaryList(params:GetList|"") {
        return service({
            url: BASE_URL+'/api/admin/dictionary/list',
            params
        });
    },
    /* 字典启用禁用 */
    updateState(params: Update) {
        return service({
            url:BASE_URL+ `/api/admin/dictionary/updateState/${params.id}`,
            method: "PUT",
            params
        });
    },
    /* 编辑字典 */
    updateDictData(data: Props) {
        return service({
            url: BASE_URL+`/api/admin/dictionary/${data.id}`,
            method: "PUT",
            data
        });
    },
    /* 删除 */
    deleteDictById(params: { id: string }) {
        return service({
            url: BASE_URL+`/api/admin/dictionary/${params.id}`,
            method: "DELETE",
        });
    },
    /* 根据id 查询详情 */
    getDictById(params: { id: string }) {
        return service({
            url: BASE_URL+`/api/admin/dictionary/${params.id}`,
        });
    }
}
export default Dict;

