import BannerAPI from "@/api/modules/banner";
import { ListData } from "@/utils/tsModules/banner";
import { Button, Input, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableList from "./components/Table";
import "./index.less";
const Banner = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [listData, setListData] = useState<any>("");
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const conponNameRef = useRef<any>();
  const navigate = useNavigate();
  const [page, setListPage] = useState<ListData>({
    current: 1,
    size: 10,
    name: "",
  });
  useEffect(() => {
    getListData();
  }, [page]);

  const getListData = async () => {
    setLoading(true);
    const res: any = await BannerAPI.getBannerList(page);
    console.log(res, "优惠券的列表");
    if (res.code === 200) {
      setLoading(false);
      setListData([...res.data.records]);
      setTotal(res.data.total || 0);
    }
  };

  const showModal = (record: Object) => {
    navigate("/home/banner/edit");
  };

  const handleSuccess = () => {
    setIsModalVisible(false);
    getListData();
  };
  /* 搜索 */

  const searchInPut = () => {
    let obj = {
      current: 1,
      size: 10,
      name: conponNameRef.current.input.value,
    };
    console.log(obj);

    setListPage({ ...obj });
  };

  return (
    <div>
      <Space size={20} className="Space_box">
        <Input placeholder="轮播图名称" ref={conponNameRef} allowClear />

        <Button onClick={searchInPut} size="middle">
          查询
        </Button>
        <Button
          type="primary"
          onClick={() => {
            showModal("");
          }}
          size="middle"
        >
          新增
        </Button>
      </Space>
      {
        <TableList
          isModalVisible={isModalVisible}
          setListData={setListData}
          handleSuccess={handleSuccess}
          listData={listData}
          loading={loading}
          page={page}
          setPage={setListPage}
          total={total}
        ></TableList>
      }
    </div>
  );
};

export default Banner;
