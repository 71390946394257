import { service, BASE_URL, mapBaseURL } from "../request";
import { ListData } from "@/utils/tsModules/coupon";
const CouponAPI = {
    getCouponList(params: ListData) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon/list`,
            params
        });
    },
    getCouponById(params: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon/editPreparation/${params.id}`,

        });
    },
    /* 新增优惠券 */
    addCouponData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon`,
            method: 'POST',
            data
        });
    },
    /* 更新优惠券 */
    upCouponData(data: any) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon/${data.id}`,
            method: 'PUT',
            data
        });
    },
    /* 删除商家 */
    deleteCouponData(data: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon/${data.id}`,
            method: 'DELETE',

        });
    },
    /* 上架 */
    onlineCouponData(data: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon/online/${data.id}`,
            method: 'PUT',

        });
    },
    /* 下架 */
    offlineCouponData(data: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon/offline/${data.id}`,
            method: 'PUT',

        });
    },
    /* 推荐 */
    onlinerecommendData(data: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon/recommend/${data.id}`,
            method: 'PUT',

        });
    },
    /* 取消推荐 */
    deprecatedRecommendData(data: { id: string }) {
        return service({
            url: `${BASE_URL}/api/admin/coupon/coupon/deprecated/${data.id}`,
            method: 'PUT',

        });
    },
}
export default CouponAPI;