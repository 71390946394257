import {
  Button,
  message,
  Popconfirm,
  Spin,
  Switch,
  TableColumnsType,
  Modal
} from "antd";
import { Table } from "antd";
import "./table.less";
import { Key, useState } from "react";
import GOODSAPI from "@/api/modules/goods";
import SourceExchangeAPI from "@/api/modules/sourceExchange";
import { useNavigate } from "react-router-dom";

interface DataType {
  id: Key;
  name: string;
  title: string;
  disable: Number;
}

type Props = {
  isModalVisible: boolean;
  setListData: Function;
  listData: any;
  handleSuccess: any;
  loading: boolean;
  page: any;
  setPage: Function;
  total: number;
  getListData:any;
};
const TableList = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
const [dataList, setDataList] = useState<any>('');
  const navigate = useNavigate();
  const { total, page, setPage } = props;
  const onSwitchChange = async (checked: number, event: any) => {
    let res: any = "";
    if (checked) {
      res = await GOODSAPI.putDataOnline({ id: event.id });
    } else {
      res = await GOODSAPI.putDataOffline({ id: event.id });
    }
    if (res.code === 200) {
      message.success(res.msg);
      props.handleSuccess();
    }
  };
  const deleteDictById = async (data: { id: string }) => {
    console.log(data.id);
    const res: any = await SourceExchangeAPI.deleteSourceById(data);
    console.log(res);
    if (res.code === 200) {
      message.success("删除成功");
      props.handleSuccess();
    }
  };
  const showModal2 = async (id:any,ttype:string) => {
    
    const req:any=await SourceExchangeAPI.getSourceById({id});
   
    // console.log('大佬',req);
    setDataList({...req.data,ttype})
    console.log('冲阿',dataList);
    setIsModalOpen(true);

    
    
  };

  const handleOk2 = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen(false);
  };
  const exchange = async (id:any,state:any) => {
    const req: any = await SourceExchangeAPI.updateSourceById({id,state});
   if(req.code===200){
    handleCancel2();
    message.success("发货成功");
    props.getListData();
   }
  };
  const cancel = (e: any) => {
    console.log(e);
    message.error("取消");
  };
  /* 页码改变的回调 */
  const pageonChange = (page: any, pageSize: any) => {
    console.log(page);
    console.log(pageSize);
    setPage({
      ...props.page,
      current: page,
      size: pageSize,
    });
  };
  const editData = async (obj: { id: string }) => {
    navigate(`/home/goodsEdit?id=${obj.id}`);
  };
  /* 推荐 */
  const onSwitchReferee = async (checked: number, event: any) => {
    let res: any = "";
    if (checked) {
      res = await GOODSAPI.putDataOnReferee({ id: event.id });
    } else {
      res = await GOODSAPI.putDataOffReferee({ id: event.id });
    }
    if (res.code === 200) {
      message.success(res.msg);
      props.handleSuccess();
    }
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: "头像",
      dataIndex: "userAvatar",
      key: "id",
      render: (_: any, record: any) => (
        <div>
          <img src={record.userAvatar} alt="" width={40} height={40} />
        </div>
      ),
    },
    {
      title: "用户昵称",
      dataIndex: "userName",
      key: "id",
    },
    { title: "联系电话", dataIndex: "userPhone", key: "id" },
/*     {
      title: "卖价",
      dataIndex: "proSalePrice",
      key: "id",
    }, */
/*     {
      title: "活动价",
      dataIndex: "proActivityPrice",
      key: "id",
    }, */
    // {
    //   title: "总源力值(个)",
    //   dataIndex: "createTime",
    //   key: "id",
    // },
    {
      title: "兑换商品",
      dataIndex: "proPicture",
      key: "id",
      render: (_: any, record: any) => (
        <div>
          <img src={record.proPicture} alt="" width={120} height={100} />
        </div>
      ),
    },
    {
      title: "商品名称",
      dataIndex: "commodityName",
      key: "id"
    },
    {
      title: "数量",
      dataIndex: "num",
      key: "id"
    },
    {
      title: "兑换方式",
      dataIndex: "type",
      key: "id",
      render: (_: any, record: any) => (
         <div>
          {record.type?'邮寄':'自提'}
         </div>
      ),
    },
    {
      title: "兑换状态",
      dataIndex: "state",
      key: "id",
      render: (_: any, record: any) => (
        <div>
         {record.state===1&&record.exchangeCode===''?
         <button onClick={()=>{showModal2(record.id,'发货')}}>发货</button>:(record.state===0?'已兑换':"未兑换") 
        }
        </div>
     ),
    },
    {
      title: "兑换码",
      dataIndex: "exchangeCode",
      key: "id",
      
    },
    {
      title: "地址",
      dataIndex: "userAddress",
      key: "id"
    },
    {
      title: "操作",
      key: "operation",
      render: (_: any, record: any) => (
        <div className="button_box">
          <Button
            size="small"
            onClick={()=>{showModal2(record.id,'查看')}}
          >
            查看
          </Button>
          <Popconfirm
            title="确定删除记录吗？"
            onConfirm={() => {
              deleteDictById(record);
            }}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              删除
            </Button>
          </Popconfirm>
        </div>
      ),
    },
   
  ];

  return (
    <div>
      <Spin spinning={props.loading} size="large">
        <Table
          scroll={{ y: "55vh" }}
          rowKey={(record) => record.id}
          columns={columns}
          expandable={{ childrenColumnName: "dictList" }}
          dataSource={props.listData}
          pagination={{
            position: ["bottomRight"],
            pageSize: page.size,
            current: page.current,
            total: total,
            onChange: pageonChange,
          }}
        />
        {/* 发货和查看详情弹窗 */}
     <Modal title="      " footer={null} visible={isModalOpen} onOk={handleOk2} onCancel={handleCancel2}>
        <div style={{ marginLeft: '110px', marginBottom: '10px' }}>
          <div>用户昵称:&nbsp;&nbsp;{dataList.userName}</div>
          <br />
          <div>联系电话:&nbsp;&nbsp;{dataList.userPhone}</div>
          <br />
          <div style={{ display: 'flex' }}>
            兑换商品:&nbsp;&nbsp;
            <div>
              <div> {dataList.commodityName}</div>
              <div>
                <img style={{ width: '100px', height: '100px' }} src={dataList.proPicture} alt="" />
              </div>
            </div>

          </div>
          <br />
          <div>商品价格:&nbsp;&nbsp;{dataList.price}</div>
          <br />
          <div>数量:&nbsp;&nbsp;{dataList.num}</div>
          <br />
          <div>兑换方式:&nbsp;&nbsp;{dataList.type===0?'自提':'邮寄'}</div>
          <br />
          {dataList.userAddress? <div>地址:&nbsp;&nbsp;{dataList.userAddress}</div>:''}
          {dataList.extractAddress? <div>自提地址:&nbsp;&nbsp;{dataList.extractAddress}</div>:''}
          <br />
          {dataList.exchangeTime? <div>兑换时间:&nbsp;&nbsp;{dataList.exchangeTime}</div>:''}
          <br />
          <div>状态:&nbsp;&nbsp;{dataList.state===0?'已兑换':'未兑换'}</div>
          <br />
          {dataList.exchangeCode?<div>兑换码:&nbsp;&nbsp;{dataList.exchangeCode}</div>:''}
          
        </div>
        {dataList.ttype==='查看'?'':<div style={{ display: 'flex', justifyContent: 'space-between', width: '350px', marginLeft: "60px" }}>
          <Button onClick={()=>{handleCancel2()}} style={{ width: '150px' }} type="primary">取消发货</Button>

          <Button onClick={()=>{exchange(dataList.id,0)}} style={{ width: '150px' }}>确认发货</Button></div>
}
        
      </Modal>
      </Spin>
      
    </div>
  );
};

export default TableList;

/* function checked(checked: any, record: any) {
  throw new Error('Function not implemented.');
} */
